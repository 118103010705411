@use '../../components';
.referrals {
    height: 40vh;
    overflow-y: scroll;
    margin: auto 0px;
    &--option {
        display: flex;
        width: 80%;
        padding: 20px 0px;
        background-color: components.$white;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        border-radius: 5px;
    }
    &--selected-option {
        @extend .referrals--option;
        border: 3px solid components.$primary-color;
    }
    &--selected-option p {
        color: components.$primary-color;
        font-weight: bold;
    }
    &--continue-btn {
        margin-bottom: 15%;
    }
}

.referrals-form {
    &--input-label {
        color: components.$primary-color;
        margin-inline: auto;
    }
    &--input-field {
        text-align: center;
    }
}
