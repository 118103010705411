@use '../components';

.menu-parent {
    display: flex;
    width: 100%;
    box-shadow: 0 -2px 5px 0 rgba(84, 84, 84, 0.13);
    background-color: components.$white;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;

    &--arrange {
        @extend .menu-parent;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
        position: fixed;
        bottom: 0px;
        z-index: 999;
    }
}

.desktop-menu-parent {
    padding: 16px 40px;
    gap: 50px;
    border-bottom: 1px solid rgba(107, 107, 107, 0.25);
    box-shadow: none;

    .desktop-menu-children {
        display: flex;
        justify-content: space-evenly;
        gap: 45px;

        @media (max-width: 1160px) {
            gap: 15px;
        }
    }
}

.menu-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-family: 'Work Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: components.$card-payment-color;

    &--link {
        $linkVar: &;
        display: flex;
        text-decoration: none;
        justify-content: center;

        &:visited {
            color: components.$card-payment-color;
        }
        &--disable {
            @extend #{$linkVar};
            display: flex;
            justify-content: center;
        }
    }

    &:first-child {
        margin-left: 0px;
    }

    &:hover {
        color: components.$primary-color;
        transition: components.$animate;
        svg {
            path {
                fill: components.$primary-color;
                transition: components.$animate;
            }

            rect {
                fill: components.$primary-color;
                transition: components.$animate;
            }
        }

        @media (max-width: 540px) {
            color: components.$card-payment-color;

            svg {
                path {
                    fill: components.$card-payment-color;
                    transition: components.$animate;
                }

                rect {
                    fill: components.$card-payment-color;
                    transition: components.$animate;
                }
            }
        }
    }

    &.menu-child--active {
        color: components.$primary-color;
        font-weight: 600;
        transition: components.$animate;

        svg {
            rect {
                fill: components.$primary-color;
            }

            path {
                fill: components.$primary-color;
            }
        }
    }

    &--link {
        position: relative;
    }

    &--disable {
        opacity: 0.3;
    }
}

.desktop-menu-child {
    flex-direction: row;
    font-size: 16px;
    margin-left: 0px;

    .desktop-menu-icon {
        display: flex;
        width: 20px;
        margin-right: 8px;
    }

    &.menu-child--active {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            background-color: components.$secondary-color-green;
            padding: 0px 8px;
            border-radius: 4px;
            position: absolute;
            left: -8px;
            right: -8px;
            top: 0px;
            bottom: 0px;
            z-index: -1;
        }
    }
}

.menu-plus {
    align-items: center;
    background: #16a34a;
    border-radius: 100%;
    display: flex;
    height: 46px;
    justify-content: center;
    width: 46px;
}

.menu-btn-logo {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-logo-link {
    display: flex;

    .menu-logo {
        width: 34px;
    }
}

.menu-logout {
    cursor: pointer;
}

.menu-option-parent {
    position: absolute;
    bottom: 60px;
    background: #fff;
    padding: 18px 16px;
    border-radius: 5px;
    right: -3px;
    box-shadow: -5px -7px 14px 3px rgb(164 164 148 / 12%);
}

.menu-option {
    &--child {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 12px;
        margin-bottom: 17px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    &--text {
        font-weight: 500;
        width: max-content;
    }
}

.menu-content {
    text-transform: capitalize;
}
