@use '../components';

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;

    &.sm {
        width: 540px;
    }
    &.md {
        width: 720px;
    }
    &.lg {
        width: 960px;
    }
    &.xl {
        width: 1140px;
    }
    &.xxl {
        width: 1320px;
    }

    @include components.container-media();
}

.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@include components.mobile-cols(12);

@include components.create-col(12);
