@use '../components';

.modal {
    &--parent {
        position: fixed;
        z-index: 999999999999;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: #00000070;
        backdrop-filter: blur(3px);
        transition: 0.3s all;
        animation: components.$fade-in-animation;
    }

    &--body {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: components.$white;
        min-height: 200px;
        max-height: 90vh;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
        animation: components.$slide-bottom-up-animation;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &-center {
            top: 0;
            margin: auto;
            height: 50%;
            border-radius: 20px;
            left: 20px;
            right: 20px;
        }

        &-center-fix {
            @extend .modal--body-center;
            height: 80%;
        }
    }

    &--body-content {
        text-align: center;
        flex-grow: 1;
        overflow-y: auto;
    }

    &--btn-parent {
        position: absolute;
        left: 10px;
        right: 10px;
        display: flex;
        column-gap: 10px;
        bottom: 20px;
        max-width: 600px;
        margin: 0 auto;
    }

    &--cancel-btn {
        background: components.$offline-color-text;
    }

    &--body-feedback {
        background-color: components.$white;
        width: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 400px;
        max-height: 80%;
        display: flex;
        border-radius: 10px;
        flex-direction: column;
    }

    &--body-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        row-gap: 50px;
    }

    &--close-icon {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
    }

    &--success-header {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    &--farmer-icon {
        display: flex;
        justify-content: center;
    }

    &--message-container {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 0 auto;
    }

    &--success-text {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
    }

    &--top-divider {
        width: 60px;
        height: 7px;
        border-radius: 5px;
        background: rgba(22, 163, 74, 0.5);
        margin: 0 auto;
    }

    &--close-icon-btn {
        display: flex;
        justify-content: flex-end;
        z-index: 2;
    }

    &--confirm-content {
        display: flex;
        flex-direction: column;
        row-gap: 4%;
        height: 100%;
    }

    &--confirm-message {
        color: components.$dark-red-interaction;
        font-size: clamp(1.25rem, 1.25rem, 1.5rem);
        font-weight: 600;
    }
}

.duplicate-confirm-modal > div:nth-child(1) {
    min-height: 420px;
}

.modal-bg {
    background-image: url('../../assets/modal-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
