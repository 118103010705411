@use '../components';

.search--input {
    display: flex;
    position: relative;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    ::placeholder {
        font-weight: normal;
        color: components.$divide-left-color;
    }
}

.search-input {
    background-color: #fff;
    box-sizing: border-box;
    font-size: clamp(0.875rem, 2.5vw, 1rem);

    @media (max-width: 540px) {
        height: 36px;
    }

    &--default {
        padding: 6px 15px;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        border: solid 1px #cbcad4;
    }

    &--rounded {
        padding: 40px 70px;
        border-radius: 60px;
        width: 100%;
        box-shadow: 1px 4px 12px 0px #6c666819;
    }

    &--compact {
        padding: 13px 16px 13px 60px;
        border-radius: 40px;
        width: 100%;
    }

    &--btn {
        position: absolute;
        right: 0px;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        gap: 10px;
        margin: 0 0 0 51px;
        padding: 10px;
        box-shadow: 0 4px 6px 0 rgba(151, 151, 151, 0.2);
        border: solid 1px components.$primary-color;
        background-color: #fff;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;
    }
}

.secondary-icon {
    position: absolute;
    left: 24px;
}
