@use '../../scss/abstract/variables';

.goal-items-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
    justify-items: center;
}

.goal-item-center {
    grid-column-start: 1;
    grid-column-end: 3;
}

.client-goal-item-container {
    width: 85px;
    height: 85px;
    background-color: variables.$white;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.client-goal-item-borders {
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    width: 74px;
    height: 74px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: variables.$light-green-background;
}

.client-goal-item-img {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    z-index: 1;
}

.client-goal-item-name {
    position: absolute;
    top: 10px;
    left: 4px;
    font-size: 12px;
    font-family: 'Work Sans', sans-serif;
    text-align: center;
    width: 60px;
    height: 40px;
    overflow: hidden;
    z-index: 0;
}

.client-goal-item-units {
    width: 24px;
    height: 24px;
    color: variables.$white;
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 30px;
    font-size: 14px;
    display: flex;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.client-goal-item-price {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
}

.client-goal-items-banner {
    background-color: variables.$village-client-border;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #e85b5b;
    padding: 7px 30px;
    width: 100%;
}

.client-goal-items-banner--delivered {
    @extend .client-goal-items-banner;
    background-color: variables.$light-green-delivered;
}

.client-goal-items-banner--finisher {
    @extend .client-goal-items-banner;
    background-color: rgba(146, 99, 208, 0.2);
}
