@use '../components';

.filter-select-parent {
    position: relative;

    @media (max-width: 540px) {
        min-width: 43px;
    }
}

.filter-select {
    padding: 7px 44px;
    border-radius: 4px;
    border: solid 1px components.$primary-color;
    background-color: components.$white;
    width: 100%;
    height: 40px;

    @media (max-width: 540px) {
        padding: 6.5px 20px;
        background: none;
        background-color: components.$white;
        height: 36px;
    }
}

.filter-checkbox {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    accent-color: components.$primary-color;
}

.filter-parent {
    position: relative;
}

.filter-option {
    background-color: components.$white;
    box-shadow: 1px 4px 12px 0 rgba(102, 92, 92, 0.15);
    border-radius: 8px;
    position: absolute;
    min-width: 200px;
    margin-top: 8px;
    width: 100%;
    z-index: 9;

    &--child {
        padding: 12px;

        & label {
            font-size: 14px;
            font-weight: 500;
            color: components.$label-text-color;
            display: flex;
            align-items: center;
        }
    }

    &--container {
        height: 200px;
        overflow: scroll;
    }
}

.filter-select-icon {
    position: absolute;
    display: flex;
    margin-left: 16px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 540px) {
        margin-left: 0px;
        top: 0px;
        transform: none;
        left: 0px;
        justify-content: center;
        align-items: center;
        right: 0px;
        bottom: 0px;
    }
}

.select-option {
    font-size: 16px;
    font-weight: 500;
    color: #1e1e19;
}

.filter-button {
    &--parent {
        width: 80%;
        margin: 15px auto;
    }

    height: 37px;
    border-radius: 6px;
}
