@use '../components';

.topbar {
    padding: 14px 25px;
    background-color: components.$white;
    font-family: 'Work Sans', sans-serif;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    color: components.$primary-color;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media (max-width: 540px) {
        justify-content: center;
        font-size: 16px;
    }

    &-name-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 3px;
        flex-grow: 1;
        min-width: 0;
    }

    &-name-container p {
        flex-grow: 1;
        min-width: 0;
        font-size: clamp(16px, 4vw, 20px);
    }

    &-name {
        text-transform: capitalize;
    }

    .topbar-icon {
        position: absolute;
        margin-top: 4px;
    }

    .topbar-icon--back {
        left: 25px;
        cursor: pointer;
    }

    .topbar-header {
        margin-left: 24px;

        @media (max-width: 420px) {
            margin-left: 0;
            display: flex;
        }
    }

    .topbar-icon--close {
        right: 25px;
        cursor: pointer;
    }

    &--transparent {
        background: none;
        color: components.$black;
        font-size: 20px;
        font-weight: 500;
    }
}
