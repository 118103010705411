@use '../components';

.package {
    &--parent {
        position: relative;
    }
    &--size {
        font-size: 26px;
        font-weight: 600;
        line-height: 39px;
    }
    &--main {
        background: components.$package-card-background;
        width: 100%;
        max-width: 200px;
        height: 72px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-parent {
            position: relative;
        }

        &-selected {
            outline: 7px solid components.$very-dark-green;
        }
    }
    &--container-bottom-right {
        position: absolute;
        bottom: -9px;
        right: -9px;
    }
    &--quantity {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: components.$very-dark-green;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 3px 6px 2px rgb(0, 0, 0, 0.24);

        &-text {
            font-size: 16px;
            font-weight: 700;
            color: components.$white;
        }
    }
    &--price {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;

        &-parent {
            margin-top: 12px;
        }
    }

    &--add-icon {
        width: 20px;
        height: 20px;
        background: components.$light-green;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 6px 1px rgb(0, 0, 0, 0.24);
    }

    &--counter {
        position: absolute;
        bottom: -12px;
        width: 100%;
    }
}
