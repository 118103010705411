@use '../../scss/abstract/variables';

.ve-ranking {
    &--parent {
        margin-top: 40px;

        @media (max-width: 540px) {
            margin-top: 16px;
        }
    }

    &--behind-section {
        margin-top: 24px;
        margin-bottom: 16px;

        @media (max-width: 540px) {
            margin-top: 16px;
        }
    }

    &--behind-body {
        .client-contact-card {
            position: relative;
            .registered-progressbar--parent {
                padding-left: 10px;
                flex: 0.4;

                @media (max-width: 540px) {
                    padding-left: 0px;
                }
            }

            .card-client-name-abbr {
                min-width: 200px;
            }

            @media (max-width: 540px) {
                margin-bottom: 0px;
                border-bottom: 1px solid variables.$village-client-border;
                border-radius: 0px;

                &:last-of-type {
                    border-bottom: none;
                }
            }
            @media (max-width: 540px) {
                .rank-parent {
                    position: absolute;
                    right: 0;
                    top: 0;
                    left: clamp(120px, 82%, 400px);
                }
            }
            @media (max-width: 375px) {
                .rank-parent {
                    position: absolute;
                    right: 0;
                    top: 0;
                    left: clamp(120px, 82%, 400px);
                }
            }
            @media (max-width: 320px) {
                .rank-parent {
                    position: absolute;
                    right: 0;
                    top: 0;
                    left: clamp(120px, 82%, 400px);
                }
            }
        }

        .card-client-parent {
            gap: 20px;

            @media (max-width: 540px) {
                gap: 0px;
            }
        }

        @media (max-width: 540px) {
            border: 1px solid variables.$village-client-border;
            border-radius: 8px;
            overflow: hidden;

            .card-client-parent {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    &--ranking-alignment {
        .card-client-parent {
            gap: 20px;
            display: grid;
            grid-template-columns: 4fr 1fr;
            @media (max-width: 540px) {
                gap: 0px;
                display: grid;
                grid-template-columns: 1fr;
            }
        }
    }

    &--progress-bar {
        @media (max-width: 540px) {
            border-left: none;
            font-size: 12px;
            width: calc(100% - 100px);
            margin-top: 4px;
            margin-bottom: 6px;
            margin-left: 58px;

            &:last-of-type {
                margin-bottom: 0px;
            }

            .stats-icon {
                width: 12px;
            }
        }
    }

    &--header-section {
        margin-bottom: 16px;
        font-size: 16px;

        @media (max-width: 540px) {
            margin-bottom: 8px;
        }
    }
}

.ranking-target-percentage {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    .ranking-enrolled {
        font-size: 12px;
        display: flex;
        gap: 6px;
        color: variables.$card-payment-color;
        border-left: 1px solid variables.$darker-grey-desktop;
        padding-left: 10px;
    }

    @media (max-width: 540px) {
        margin-left: 58px;

        .ranking-enrolled {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            gap: 6px;
            margin-bottom: 4px;
        }
    }
}

.ranking-enrolled {
    font-size: 12px;
    display: flex;
    gap: 6px;
    color: variables.$card-payment-color;
    border-left: 1px solid variables.$darker-grey-desktop;
    padding-left: 10px;

    @media (max-width: 540px) {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        gap: 6px;
        margin-bottom: 4px;
        margin-left: 58px;
        padding-left: 0px;
        border-left: 0px;
    }
}

.ranking-village {
    .card-client-data {
        .card-client-id--payment {
            color: variables.$label-text-color;
        }
    }
}

.objective-revenue {
    &--parent {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;

        .registered-progressbar {
            &--parent.ve-ranking--progress-bar {
                flex: 1;
                &:last-of-type {
                    margin-left: 5px;
                    padding-left: 5px;
                    margin-top: 0px;
                    position: relative;

                    @media (max-width: 540px) {
                        &::before {
                            position: absolute;
                            content: '';
                            top: 50%;
                            bottom: 0px;
                            width: 1px;
                            background-color: variables.$darker-grey-desktop;
                            height: 80%;
                            left: 0px;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
}
