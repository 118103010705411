@use '../../scss/abstract/variables';

.gps-warning-message {
    font-size: clamp(16px, 19px, 21px);
    font-weight: 500;
    text-align: center;
    width: 100%;
    color: variables.$label-text-color;
}

.gps-warning-container {
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: variables.$offline-color;
}

.gps-warning-instruction-container {
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.gps-warning-instructions-text {
    margin-left: 20px;
    font-size: clamp(14px, 16px, 18px);
}

.gps-warning-instructions-title {
    margin-bottom: 10px;
    font-size: clamp(14px, 16px, 18px);
}

.gps-warning-animated-instructions {
    max-height: 400px;
    display: block;
    margin: 20px auto;
    border: 1px solid black;
}

.gps-warning-button {
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 420px) {
        margin-bottom: 70px;
    }
    width: 100%;
    margin: 0 auto;
}

.line-separator {
    background-color: black;
    height: 2px;
    width: 100%;
}
