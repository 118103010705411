@use '../components';

.progressbar-parent {
    width: 100%;
    height: 8px;
    border-radius: 2px;
    overflow: hidden;
}

.progressbar--small {
    height: 6px;
}

.progressbar-rail--red {
    background-color: components.$light-grey;
}

.progressbar-rail--yellow {
    background-color: components.$light-grey;
}

.progressbar-rail--green {
    background-color: components.$blue-progress-bar;
}

.progressbar-gauge {
    height: 100%;
    display: block;
    border-radius: 2px;
}

.progressbar-gauge--red {
    background-color: components.$blue-progress-bar;
}

.progressbar-gauge--yellow {
    background-color: components.$blue-progress-bar;
}

.progressbar-gauge--green {
    background-color: components.$blue-progress-bar;
}
