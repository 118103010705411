@use '../../../scss/abstract/variables';

.voucher-code-title {
    font-size: 26px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.manual-typing-button {
    background-color: white;
    border: 1px solid variables.$primary-color;
    color: black;
    margin-top: 50px;
}

.modal--body-bottom {
    height: fit-content;
    min-height: 300px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.card-amount-options-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.card-amount-option {
    background-color: variables.$white;
    border: 1px solid variables.$primary-color;
    border-radius: 10px;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 1000;
}

.card-amount-selected {
    background-color: variables.$selected-card-amount;
    border-color: variables.$primary-color;
}

// add marging top for client-details--bottom-button with parent class modal--body--content
.modal--body-content {
    .client-details--bottom-button {
        margin-top: 20px;
    }
}

// horizontally center all the elements in card-payment-content
.card-payment-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
