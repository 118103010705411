@use '../../scss/abstract/variables';

.visit-header {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.question-title {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.warning {
    &--modal {
        &-body {
            margin-top: 50px;
            width: 200px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
        &-text {
            color: variables.$red;
            font-size: 20px;
        }
    }
}

.modal--header {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
}

.modal--cancel-btn {
    background-color: variables.$white;
    border-color: variables.$warning-button-border-color;
    color: variables.$black;
}
