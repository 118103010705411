.select-input {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 1px #cbcad4;
    background-color: #fff;
    font-family: 'Work Sans', sans-serif;

    @media (max-width: 580px) {
        height: 40px;
    }
}
