@use '../components';

.link-anchor {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: components.$primary-color;
    text-decoration: underline;
}
