@use '../components';

input[type='radio']:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: components.$village-client-border;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid components.$village-client-border;
    box-shadow: inset 0px 0px 4px -2px rgba(0, 0, 0, 0.15);
}

input[type='radio']:checked:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: components.$primary-color;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid components.$primary-color;
    box-shadow: none;
}

.radio-button {
    display: flex;
    align-items: center;

    &--label {
        padding-left: 10px;
    }

    input[type='radio'] {
        visibility: hidden;
    }
}
