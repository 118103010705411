@use '../components';

.alert-parent {
    display: flex;
    justify-content: center;
    animation: components.$slide-down-animation;
}

.alert-parent--floating {
    position: fixed;
    width: 90%;
    bottom: 84px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 12px 27px;
    border-radius: 8px;
    box-shadow: 0 2px 15px 5px rgba(133, 124, 124, 0.12),
        0 4px 12px 0 rgba(0, 0, 0, 0.15);
    max-width: 794px;
    animation: components.$slide-up-animation;
    z-index: 99999;
}

.alert--error {
    background: components.$offline-color;
}

.alert--success {
    background: components.$online-color;
}

.alert-default {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    display: flex;
    color: components.$alert-color-top-text;
    padding: 7.5px 0px;
    font-family: 'Work Sans', sans-serif;

    @media (max-width: 540px) {
        font-size: 12px;
        padding: 3px 0px;
    }

    @media (max-width: 400px) {
        font-size: 11px;
    }
}

.alert-floating {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
}

.alert-floating--success {
    color: components.$primary-color;
}

.alert-floating--error {
    color: components.$offline-color-text;
}

.alert-icon-parent {
    display: flex;
    margin-right: 6px;
}

.alert-icon-status {
    margin-left: 4px;
    font-weight: 500;
    @media (max-width: 400px) {
        margin-left: 1px;
    }
}

.alert-icon-status--error {
    color: components.$offline-color-text;
}

.alert-icon-status--success {
    color: components.$primary-color;
}

.alert-content {
    font-weight: 500;
    @media (max-width: 320px) {
        font-size: 10px;
    }
}
.alert-extended {
    display: flex;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    width: 80%;
    margin: auto;
    @media (max-width: 320px) {
        font-size: 10px;
    }
}

.prefix-text {
    font-weight: 500;
    margin-right: 10px;
    @media (max-width: 400px) {
        margin-right: 3px;
    }

    &--success {
        color: components.$primary-color;
    }

    &--error {
        color: components.$offline-color-text;
    }
}
