@use '../components';

.visit-form-button {
    width: 100%;
    margin: auto;
    margin-top: 15px;
    @media (max-width: 420px) {
        margin-bottom: 70px;
    }
}
