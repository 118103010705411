@use '../components';

.tab-primary {
    box-shadow: none;
    background-color: transparent;
    display: flex;
    justify-content: space-around;

    &::after {
        content: '';
        position: absolute;
        height: 2px;
        left: 15px;
        right: 15px;
        bottom: 0px;
        border-bottom: 1px solid components.$darker-grey-desktop;
        z-index: -1;
    }

    @media (max-width: 540px) {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: components.$white;
    }
}

.tab-pos {
    &::before {
        content: '';
        position: absolute;
        height: 2px;
        background: components.$primary-color;
        transition: 0.5s all;
        bottom: 0px;
    }

    &-0::before {
        left: 0%;
        right: 50%;
    }

    &-1::before {
        left: 50%;
        right: 0%;
    }
}

.tab-child-primary {
    padding-top: 40px;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2f2f20;
    width: 180px;
    text-align: center;
    cursor: pointer;
    flex: 1;

    @media (max-width: 540px) {
        padding-top: 22px;
        font-size: 14px;
    }
}

.tab-child-link {
    margin-bottom: 6px;

    @media (max-width: 540px) {
        margin-bottom: 9px;
    }
}

.tab-child-primary--active {
    color: components.$primary-color;

    .tab-child-link {
        font-weight: 600;
    }
}

.tab-child-body {
    position: absolute;
    left: 0px;
    right: 0px;
    margin-top: 22px;
    text-align: initial;

    @media (max-width: 540px) {
        margin-top: 21px;
    }
}
