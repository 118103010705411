@use '../components';

.checkbox-parent {
    position: relative;
    cursor: pointer;
    padding-right: 20px;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1e1e19;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0px;
        width: 0px;

        &:checked {
            ~ .checkbox-checkmark {
                background-color: components.$primary-color;
                transition: components.$animate;
            }
            ~ .checkbox-checkmark {
                &:after {
                    display: block;
                }
            }
        }
    }

    &:hover {
        input {
            ~ .checkbox-checkmark {
                background-color: #ccc;
                transition: components.$animate;
            }
        }
    }

    .checkbox-checkmark {
        &:after {
            left: 5px;
            top: 2px;
            width: 3px;
            height: 7px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border-radius: 2px;
        }
    }
}

.checkbox-checkmark {
    position: absolute;
    left: 0px;
    top: 0px;
    background: #e4e4e7;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    width: 16px;
    height: 16px;

    &:after {
        content: '';
        position: absolute;
        display: none;
    }
}
