@use '../../scss/abstract/variables';

.tabs {
    &--search {
        padding: 20px;
        background-color: variables.$white;
        width: 100%;

        .search-input--compact {
            border: 1px solid variables.$light-green-search-border;
        }
    }

    &--buttons {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0;

        .active {
            background-color: variables.$white;
            border-radius: 10px 10px 0 0;

            svg {
                fill: variables.$grey;
            }
        }

        .blue {
            color: variables.$main-blue;

            path {
                fill: variables.$main-blue;
            }

            svg {
                fill: variables.$main-blue;
            }
        }

        .green {
            color: variables.$primary-color;

            svg {
                fill: variables.$primary-color;
            }
        }
    }

    &--button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        color: variables.$grey;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        font-family: "Work Sans", sans-serif;
        padding: 8px 0;
    }

    &--items {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .downloadable-card {
            margin-bottom: 10px;
        }
    
        .downloadable-card:last-child {
            margin-bottom: 0;
        }
    }

    &--empty {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 30px;
    }

    &--empty-text {
        display: flex;
        align-items: center;
        border-radius: 16px;
        background: variables.$white;
        box-shadow: 3.372px 3.372px 8.431px 0px rgba(0, 0, 0, 0.1);
        padding: 30px;
        justify-content: center;
        width: 220px;
        text-align: center;
    }
}
