// COLOR
$offline-color: #f9ebe9;
$online-color: #ebfef5;
$dark-grey: #655f5f;
$grey: #979797;
$red: #e85b5b;
$main-blue: #2c70e3;
$maroon: #a96778;
$brown: #a3743b;
$purple: #9263d0;
$error-red: #ff1515;
$deep-red: #ff0000;
$offline-color-text: $red;
$primary-color: #16a34a;
$product-carousel-pagination-green: rgba($primary-color, 0.5);
$selected-card-amount: rgba($primary-color, 0.1);
$secondary-color-green: #f1fdf5;
$alert-color-top-text: #001500;
$white: #ffffff;
$black: #000000;
$default-text: #231f20;
$dark-gray: #adacac;
$body-bg-color: #f6f6f6;
$deep-header-text-color: #176d2a;
$label-text-color: #1e1e19;
$home-card-black: #2f2f20;
$dark-green: #096127;
$very-dark-green: #186d2a;
$light-green: #c0ddcb;
$light-black: #545454;
$mid-black: #abaeb1;
$thin-black: #e0e0e0;
$divide-color: #cbcad4;
$divide-left-color: #979797;
$card-payment-color: #636363;
$card-sale-black: #555555;
$orange: #ff5f04;
$rgba-mid-black: rgba(2, 2, 2, 0.2);
$rgba-black: rgba(0, 0, 0, 0.4);
$darker-grey: #6b6b6b;
$darker-grey-desktop: #e1e1e1;
$village-client-border: #e4e4e7;
$lemon-green: #9a8603;
$yellow-progress-bar: #ebcf3c;
$yellow-progress-bar-rail: #f9f7e9;
$name-abbr-bg: #f2f2f2;
$faded-red: rgb(251 195 195);
$training-bg-color: #fed673;
$green-interaction: #bee9d6;
$orange-interaction: #ffe299;
$red-interaction: #fcd2cc;
$dark-red-interaction: #ff1515;
$no-visit-background: #c4c4c4;
$target-list-status-text: #231f20;
$light-grey: #d9d9d9;
$warning-button-border-color: #cfe5eb;
$follow-up-interaction-btn: #4982cd1a;
$follow-up-interaction-btn-text-color: #4982cd;
$not-interested-interaction-btn: #9797971a;
$not-interested-interaction-btn-text-color: #979797;
$default-interaction-btn: #fdba161a;
$default-interaction-btn-text-color: #ea7e00;
$product-card-border: #b5b5b5;
$light-green-background: #d0eddb;
$package-card-background: #ba976b;
$modal-background-color: #d6f0db;
$cancel-duplicate-background: #dadada;
$follow-up-background: #fff1cd;
$visit-filter-background: #e0eee5;
$handshake-icon-color: #b6b5b5;
$very-light-blue: #eaf1fc;
$light-green-delivered: rgba(129, 210, 25, 0.2);
$light-green-search-border: rgba(22, 163, 74, 0.5);
$group-role-text-color: #dadadb;
$group-role-selected: #a3743b33;
$group-role-box-shadow: #97979740;
$blue-progress-bar: #2c70e3;
$grey-progress-bar: #999999;
$fc-brown-color: #a3743b;
$lighter-green: #8ad1a4;
// List of variables to be used programatically
:export {
    yellowProgressBar: $yellow-progress-bar;
    blueProgressBar: $blue-progress-bar;
    trainingBgColor: $training-bg-color;
    white: #ffffff;
    bodyBgColor: $body-bg-color;
    primaryColor: $primary-color;
}

$animate: all 0.5s;
$slide-up-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;

$slide-down-animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) -20ms
    both;

$slide-bottom-up-animation: slide-in-blurred-bottom-up 0.6s
    cubic-bezier(0.23, 1, 0.32, 1) both;

$fade-in-animation: fade-in 0.9s cubic-bezier(0.23, 1, 0.32, 1) both;

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
        transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
        transform: translate(-50%, 0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-36px);
        transform: translateY(-36px);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
    0% {
        -webkit-transform: translate(0%, 1000px);
        transform: translate(0%, 1000px);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(0%, 0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

:root {
    --gap-ratio: 0.5;
    --char-w: 0.95ch;
    --gap: calc(var(--gap-ratio) * var(--char-w));
    --n-char: 10;
    --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

@function dotted-line($color) {
    @return repeating-linear-gradient(
            90deg,
            $color 0,
            $color var(--char-w),
            transparent 0,
            transparent calc(var(--char-w) + var(--gap))
        )
        0 100% / calc(var(--in-w) - var(--gap)) 2px no-repeat;
}
