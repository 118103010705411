@use '../../scss/abstract/variables';

.notification {
    &--parent {
        margin-top: 19px;
    }

    &--header {
        margin-bottom: 20px;
    }

    &--spinner-parent {
        margin-top: 40px;
    }

    &--body {
        &::before {
            content: '';
            position: absolute;
            top: 155px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: variables.$white;
        }
    }

    &--container {
        background: variables.$white;
        margin-top: 80px;
    }

    &--no-notification {
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        color: variables.$offline-color-text;
    }
}

.payment-notification {
    border-radius: 24px;
    padding: 12px 24px;
    background-color: variables.$white;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0px;
    }

    &--card-number {
        font-size: 1.188rem;
        color: variables.$label-text-color;
        font-weight: 600;
    }

    &--client-code {
        font-size: 0.875rem;
        font-weight: 400;
        color: variables.$card-payment-color;

        &--green {
            color: variables.$primary-color;
        }
    }

    &--icon-parent {
        width: 47px;
        height: 47px;
        border-radius: 100%;
        border: 1px solid variables.$primary-color;
        display: flex;
        justify-content: center;
        align-items: center;

        &.pending {
            border: 1px solid variables.$divide-left-color;
        }

        &.offline {
            border: 1px solid variables.$divide-left-color;
        }

        &.failed {
            border: 1px solid variables.$offline-color-text;
        }
    }

    &--row {
        margin-bottom: 27px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &:last-child {
            margin-bottom: 0px;
            align-items: flex-end;
        }
    }

    &--date-time {
        font-size: 0.75rem;
        font-weight: 400;
        color: variables.$card-payment-color;
    }

    &--amount {
        color: variables.$home-card-black;
        font-size: 1.063rem;
        font-weight: 600;
    }
}

.client-code {
    &--green {
        color: variables.$primary-color;
        text-decoration: underline;
    }
}

.notification-filter {
    flex: 1;
    display: flex;
    flex-direction: column;

    &--parent {
        display: flex;
        justify-content: space-between;
        gap: 17px;
        margin-bottom: 32px;
    }

    &--icon {
        &-parent {
            width: 39px;
            height: 39px;
            background-color: variables.$white;
            border-radius: 100%;
            border: 1px solid variables.$divide-left-color;
            margin-bottom: 18px;
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
                border: 1px solid variables.$primary-color;
            }
        }
    }

    &--text {
        color: variables.$black;
        text-align: center;
        font-size: 0.75rem;
        font-weight: 500;

        &-parent {
            padding: 5px 10px;
            border: 1px solid variables.$village-client-border;
            border-radius: 9px;
            background-color: variables.$village-client-border;

            &.active {
                border: 1px solid variables.$primary-color;
                background-color: variables.$white;
            }
        }
    }
}

.notification-search {
    border: 1px solid variables.$divide-left-color;
    border-radius: 24px;
    padding: 12px;
    background-color: variables.$white;
    display: flex;
    justify-content: space-between;
    margin-bottom: 31px;
    margin-top: 13px;

    &--input {
        flex: 1;
        padding: 0px 10px;
        font-family: 'Work Sans';
    }

    &--icon {
        display: flex;
        margin-right: 12px;
    }
}

.notification-modal {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #00000036;
    z-index: 9999999999999;

    &--bg {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }

    &--close {
        position: absolute;
        top: 21px;
        right: 29px;
    }

    &--main {
        background: #fff;
        border-radius: 20px;
        height: 70%;
        margin: auto;
        width: 80%;
        z-index: 99999999999999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 52px 30px 46px;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &--msg {
        font-size: 16px;
        font-weight: 400;
        color: variables.$black;
        text-align: center;

        &-parent {
            width: 80%;
            margin: auto;
        }
    }

    &--icon-parent {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid variables.$divide-left-color;

        &.failed {
            border: 1px solid variables.$offline-color-text;
        }

        &.success {
            border: 1px solid variables.$primary-color;
        }

        & svg {
            width: 14px;
        }
    }

    &--header {
        font-size: 14px;
        font-weight: 600;
        color: variables.$black;

        &-parent {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: center;
        }
    }

    &--details {
        &-header {
            font-size: 14px;
            color: variables.$black;
            font-weight: 400;
        }

        &-body {
            margin-top: 13px;
        }

        &-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            align-items: center;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        &-content {
            font-size: 12px;
            color: variables.$black;
            font-weight: 600;
        }

        &-text {
            font-weight: 400;
        }
    }

    &--status-tag {
        padding: 3px 5px;
        border-radius: 5px;
        background-color: variables.$light-grey;

        &.failed {
            background-color: variables.$red-interaction;
        }

        &.success {
            background-color: variables.$green-interaction;
        }

        &-text {
            font-size: 12px;
            color: variables.$black;
            font-weight: 500;
        }
    }
}

.no-payment-notification {
    padding: 54px 27px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    text-align: center;
    max-width: 267px;
    margin: auto;
    margin-top: 70px;
    font-size: 14px;
}

.single-notification {
    &--container {
        margin-top: 20px;

        &:last-child {
            margin-bottom: 85px;

            & .single-notification--content {
                border-bottom: 0px;
            }
        }
    }
    &--content {
        display: flex;
        position: relative;
        padding: 31px 20px;
        gap: 20px;
        align-items: center;
        border-bottom: 1px solid variables.$light-grey;
        margin-top: 10px;
    }
    &--content-unread {
        background: rgba(44, 112, 227, 0.1);
    }
    &--icon {
        display: flex;
        width: 45px;
        height: 45px;
        align-items: center;
        justify-content: center;
    }
    &--text {
        flex: 1;
    }
    &--text-content {
        color: variables.$card-payment-color;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        display: -webkit-box;
        max-width: fit-content;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &--dot {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: variables.$deep-red;
    }
    &--date {
        font-size: 12px;
        font-weight: 500;
        color: variables.$card-payment-color;
        line-height: 18px;
        text-transform: capitalize;
    }
}

.status-notification {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 70px;
}

.status-icon {
    margin-top: 60px;
}

.top-close-bg {
    background: variables.$very-light-blue;
    height: 64px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
}

.modal-content-parent {
    margin-top: 54px;
    margin-bottom: 50px;
}

.modal-content {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: variables.$card-payment-color;
}

.modal--body-bottom {
    padding-top: 20px;
}

.modal-client-id {
    color: variables.$darker-grey;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-align: left;
}

.modal-client-name {
    color: black;
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    line-height: 150%; /* 24px */
}

.modal-client-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-left: 15%;
    border-bottom: 1px solid variables.$light-grey;
}

.modal-notification-container {
    min-height: 430px;
}
