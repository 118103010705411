@use '../../scss/abstract/variables';

.profile-hearder-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.profile-title {
    height: 30px;
    font-size: 18px;
    font-weight: 400;
    width: fit-content;
    margin: auto;
}

.profile-user-info {
    text-align: center;
    margin-top: 20px;
}

.profile-name {
    color: variables.$default-text;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
}

.profile-id {
    font-size: 16px;
    font-weight: 400;
}

.profile-logout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    border: 1px solid variables.$main-blue;
}

.profile-buttons-container {
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.profile-button {
    display: flex;
    border-radius: 5px;
    width: 75px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 5px;
    background-color: variables.$white;
    color: variables.$main-blue;
}
.deposits-link {
    color: variables.$main-blue;
}

.deposit-card {
    background: variables.$white;
    border-radius: 10px;
    margin-top: 15px;
}

.profile-card-session {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    background: variables.$white;
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    padding: 20px 15px;
    margin: 32px 0px 16px;
}

.header-month {
    color: variables.$darker-grey;
    font-family: 'Work Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: capitalize;
}

.header-card-text {
    color: variables.$default-text;
    font-family: 'Work Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding-top: 5px;
}
.weekly-objectives {
    display: flex;
    padding: 10px 0px 20px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.client-card--header {
    color: variables.$main-blue;
}

.weekly-objectives .card-parent {
    display: flex;
    width: 168px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: variables.$body-bg-color;
}

.performance-card-parent {
    background: variables.$white;
    box-shadow: 1px 4px 12px 0px rgba(133, 124, 124, 0.06);
    padding: 12px 16px;
    width: 100%;
}

.performance-card {
    background: none;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
}

.client-card--header {
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.target-progressbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 150px;
    padding-bottom: 5px;
    padding-top: 30px;
}

.progress-numbers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}

.progress-value {
    color: variables.$main-blue;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.progress-max {
    color: variables.$grey-progress-bar;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    line-height: 150%;
    font-weight: 600;
}

.progress-value.blue,
.progress-max.blue {
    color: variables.$main-blue;
    font-weight: 700;
    font-size: 24px;
}
.ve-card-sale {
    &--header {
        &-title {
            color: var(--Greys-Dark-grey, #1e1e19);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;
        }
        &-month {
            color: #6b6b6b;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }
    &--total-payment {
        color: #979797;
        font-family: 'Work Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        display: flex;
        padding: 2px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
    }
    &--progressbar {
        display: flex;
        padding: 10px 0px;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        color: #231f20;
        text-align: center;
        font-family: 'Work Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }
    &--sales-title {
        color: variables.$primary-color;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }
    &--not-available {
        margin-bottom: 20px;
    }
}

.text-green {
    color: variables.$primary-color;
}

.ve-card-sale .splide__pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.splide__pagination__item {
    margin: 0 5px;
}
.splide__pagination__page.is-active {
    background: variables.$lighter-green;
}
.profile {
    &--card {
        &-deposited-text {
            font-weight: 600;
            font-size: 32px;
            line-height: 150%;
            color: variables.$main-blue;
        }
        &-row {
            margin: 50px 0px;
        }
        &-deposits {
            width: 100%;
            padding: 0px 10px;
            cursor: pointer;
        }
    }
}
