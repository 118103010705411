:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2c70e3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

* {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
label,
option,
input[type=text],
input[type=password],
input[type=number],
select,
a {
  font-family: "Work Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.header-welcome {
  font-family: "Zilla Slab", serif;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

:root {
  --app-background: variables.$body-bg-color;
  --client-subscribed-color: variables.$yellow-progress-bar;
  --primary-color: variables.$primary-color;
}

body {
  background-color: var(--app-background);
}
body .clear-h-padding {
  padding-left: 0px;
  padding-right: 0px;
}
body .flex-0 {
  flex: 0;
}
body .flex-1 {
  flex: 1;
}
body .content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.page-wrapper {
  margin-bottom: 100px;
}

.form-label {
  font-size: 16px;
  margin-bottom: 4px;
  display: inline-block;
  color: #1e1e19;
}

.form-label.required::after {
  content: "*";
  color: #1e1e19;
  margin-left: 5px;
}

.form-label.required.error::after {
  color: #e85b5b;
}

.display-flex {
  display: flex;
}

.space-btw {
  justify-content: space-between;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.fd-col {
  flex-direction: column;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.ai-fe {
  align-items: flex-end;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("/public/images/dropdown-icon.svg");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
}

.header-bar {
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 99;
}

.header-bar--transparent {
  background-color: transparent !important;
}

.header-bar-shadow {
  box-shadow: 1px 4px 19px -7px rgba(0, 0, 0, 0.12);
  background: #ffffff !important;
}

.tellPhone-link {
  color: #16a34a;
  text-decoration: none;
}

.phone-number-text {
  font-weight: 600;
}
.phone-number-text--follow_up {
  text-decoration: none;
  color: #4982cd;
}
.phone-number-text--not_interested {
  text-decoration: none;
  color: #979797;
}
.phone-number-text--default {
  text-decoration: none;
  color: #ea7e00;
}
.phone-number-text--duplicate {
  text-decoration: none;
  color: #6b6b6b;
}
.phone-number-text--normal {
  text-decoration: none;
  color: #16a34a;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.responsive-btn-font {
  font-size: clamp(1rem, 2vw, 1.25rem);
}

/**
 * ----------------------------------------
 * animation transition-left-2
 * ----------------------------------------
 */
@keyframes transition-left-2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0% -50%;
    transform-origin: 0% -50%;
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transform-origin: -50% 0%;
    transform-origin: -50% 0%;
  }
}
@keyframes transition-right-1 {
  0% {
    -webkit-transform: translateX(-67%);
    transform: translateX(-67%);
    -webkit-transform-origin: -34% 0%;
    transform-origin: -34% 0%;
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0% -50%;
    transform-origin: 0% -50%;
  }
}
/**
 * ----------------------------------------
 * animation slide-left-step-2
 * ----------------------------------------
 */
@keyframes slide-left-2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0% -33%;
    transform-origin: 0% -33%;
  }
  100% {
    -webkit-transform: translateX(-33%);
    transform: translateX(-33%);
    -webkit-transform-origin: -33% 0%;
    transform-origin: -33% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-left-step-3
 * ----------------------------------------
 */
@keyframes slide-left-3 {
  0% {
    -webkit-transform: translateX(-33%);
    transform: translateX(-33%);
    -webkit-transform-origin: -66% 0%;
    transform-origin: -66% 0%;
  }
  100% {
    -webkit-transform: translateX(-66%);
    transform: translateX(-66%);
    -webkit-transform-origin: -66% 0%;
    transform-origin: -66% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-right-step-2
 * ----------------------------------------
 */
@keyframes slide-right-2 {
  0% {
    -webkit-transform: translateX(-67%);
    transform: translateX(-67%);
    -webkit-transform-origin: -34% 0%;
    transform-origin: -34% 0%;
  }
  100% {
    -webkit-transform: translateX(-33%);
    transform: translateX(-33%);
    -webkit-transform-origin: -33% 0%;
    transform-origin: -33% 0%;
  }
}
/**
 *
 * ----------------------------------------
 * animation slide-right-step-1
 * ----------------------------------------
 */
@keyframes slide-right-1 {
  0% {
    -webkit-transform: translateX(-33%);
    transform: translateX(-33%);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
.generic-enter,
.generic-appear {
  opacity: 0;
  transform: translateY(100px);
}

.generic-enter-active,
.generic-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 700ms, transform 700ms;
}

.animate-show-1 {
  transform: translateY(30px);
}

.animate-showed-1 {
  transform: translateY(0px);
  transition: 700ms;
}

.animate-show-2 {
  transform: translateY(65px);
}

.animate-showed-2 {
  transform: translateY(0px);
  transition: 800ms;
}

.animate-show-3 {
  transform: translateY(100px);
}

.animate-showed-3 {
  transform: translateY(0px);
  transition: 900ms;
}

.animate-show-4 {
  transform: translateY(135px);
}

.animate-showed-4 {
  transform: translateY(0px);
  transition: 1000ms;
}

.animate-show-5 {
  transform: translateY(170px);
}

.animate-showed-5 {
  transform: translateY(0px);
  transition: 1100ms;
}

.animate-show-6 {
  transform: translateY(205px);
}

.animate-showed-6 {
  transform: translateY(0px);
  transition: 1200ms;
}

.animate-show-7 {
  transform: translateY(240px);
}

.animate-showed-7 {
  transform: translateY(0px);
  transition: 1300ms;
}

.animate-show-8 {
  transform: translateY(275px);
}

.animate-showed-8 {
  transform: translateY(0px);
  transition: 1400ms;
}

.animate-show-9 {
  transform: translateY(310px);
}

.animate-showed-9 {
  transform: translateY(0px);
  transition: 1500ms;
}

.animate-show-10 {
  transform: translateY(345px);
}

.animate-showed-10 {
  transform: translateY(0px);
  transition: 1600ms;
}

.animate-show-11 {
  transform: translateY(380px);
}

.animate-showed-11 {
  transform: translateY(0px);
  transition: 1700ms;
}

.animate-show-12 {
  transform: translateY(415px);
}

.animate-showed-12 {
  transform: translateY(0px);
  transition: 1800ms;
}

.version--text {
  color: #979797;
}

.client-details-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: clamp(0.875rem, 2.5vw, 1rem);
}
.client-details-data--header-title {
  color: #636363;
}
.client-details-data--edit {
  display: flex;
  column-gap: 5px;
  border-bottom: 1px solid #16a34a;
  color: #16a34a;
  align-items: center;
  cursor: pointer;
}

.generic--error-message {
  color: #e85b5b;
  font-size: clamp(0.75rem, 2.5vw, 0.875rem);
  text-align: center;
}
.generic--error-border {
  border-color: rgb(251, 195, 195) !important;
}

.form-parent {
  max-width: 794px;
  margin: 0 auto;
  margin-top: 40px;
}
.form-parent .formgroup {
  margin-top: 24px;
}
.form-parent .formgroup:first-child {
  margin-top: 0px;
}
@media (max-width: 420px) {
  .form-parent .formgroup {
    margin-top: 16px;
  }
}
.form-parent label {
  margin-bottom: 4px;
  display: block;
  color: #1e1e19;
  font-size: 16px;
}
@media (max-width: 420px) {
  .form-parent {
    margin-top: 33px;
  }
}

.card-client-abbr {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  border: solid 1px #e4e4e7;
  background-color: #f2f2f2;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #6b6b6b;
  flex-shrink: 0;
}
.card-client-abbr--name {
  width: 64px;
  height: 64px;
  font-size: 16px;
}
@media (max-width: 540px) {
  .card-client-abbr--name {
    width: 56px;
    height: 56px;
  }
}
.card-client-abbr--low {
  background-color: #e85b5b;
}
.card-client-abbr--medium {
  background-color: #ebcf3c;
}
.card-client-abbr--high {
  background-color: #16a34a;
}

.loading-text {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.qr-code-button--parent {
  width: 70px;
  height: 70px;
  background: #16a34a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  left: -33px;
}

.menu--unread-notification-dot {
  border-radius: 50%;
  height: 10px;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 10px;
  background: #ff0000;
}
.menu--notification-icon {
  position: relative;
}

