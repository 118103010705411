@use '../../scss/abstract/variables';

.home-main {
    margin-top: 45px;

    @media (max-width: 420px) {
        margin-top: 12px;
    }
}

.ranking-link-col {
    text-align: right;

    @media (max-width: 540px) {
        text-align: center;
    }
}

.ranking-link {
    font-size: 18px;
    font-weight: 500;

    @media (max-width: 420px) {
        font-size: 14px;
    }
}

.deposits-link {
    font-weight: 500;
}

.home-welcome--text {
    font-size: 28px;
    font-weight: 600;
    color: variables.$label-text-color;

    @media (max-width: 540px) {
        font-size: 19px;
    }
}

.home-welcome--text-large {
    @media (max-width: 540px) {
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
    }
}

.home-commission-card {
    margin-top: 19px;

    @media (max-width: 420px) {
        margin-top: 16px;
    }
}

.card-session {
    margin: 32px 0px 16px;

    @media (max-width: 420px) {
        margin: 24px 0px 8px;
    }
}

.performance-card-parent {
    height: 100%;
}

.session-header {
    font-size: 19px;
    font-weight: 500;
    color: variables.$label-text-color;

    @media (max-width: 420px) {
        font-size: 16px;
    }
}

.card-sale-parent {
    border-bottom: 1px solid variables.$divide-color;

    .card-sale {
        display: flex;
        justify-content: space-between;
        color: variables.$home-card-black;

        &--header {
            font-size: 18px;
            font-weight: bold;

            @media (max-width: 420px) {
                font-size: 16px;
            }
        }

        &--duration {
            color: variables.$light-black;
            font-weight: normal;
        }
    }

    .card-progressbar {
        margin-top: 22px;
    }

    .progressbar-numbers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0px;

        @media (max-width: 420px) {
            margin: 11px 0px;
        }

        &--amount {
            font-size: 23px;
            font-weight: bold;
            color: variables.$home-card-black;

            @media (max-width: 420px) {
                font-size: 19px;
            }
        }

        &--total {
            font-size: 16px;
            font-weight: bold;
            color: variables.$light-black;

            @media (max-width: 420px) {
                font-size: 14px;
            }
        }

        &--of {
            color: variables.$mid-black;
        }
    }
}

.sales-deposit-parent {
    margin-top: 16px;
    font-size: 16px;
    color: variables.$label-text-color;

    @media (max-width: 420px) {
        margin-top: 12px;
    }

    .sales-deposit {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        font-size: 16px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.client-row {
    .client-card-parent {
        padding-left: 0px;

        @media (max-width: 540px) {
            padding: 4px;
        }
    }

    @media (max-width: 540px) {
        margin-top: 4px;
        padding: 0 11px;
    }
}

.client-card {
    color: variables.$label-text-color;

    @media (max-width: 320px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &--header {
        font-size: 16px;
        font-weight: bold;
    }
    &--light-italic {
        font-size: 14px;
        font-weight: normal;
        font-style: italic;

        @media (max-width: 420px) {
            font-size: 16px;
        }
    }
}

.second-client-card {
    margin-top: 16px;

    @media (max-width: 540px) {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.client-number {
    display: flex;
    align-items: baseline;

    &--num {
        font-size: 40px;
        font-weight: bold;

        @media (max-width: 420px) {
            font-size: 33px;
        }
    }

    &--others {
        font-size: 18px;
        font-weight: 600;

        @media (max-width: 420px) {
            font-size: 14px;
        }
    }

    &--of {
        color: variables.$mid-black;
    }

    &--den {
        color: variables.$light-black;
    }

    &--content {
        margin-right: 9px;

        @media (max-width: 420px) {
            margin-right: 3px;
        }
    }

    &--highlight {
        color: variables.$primary-color;
    }
}

.client-contact-card {
    margin-bottom: 8px;
}

.target-list {
    &--header {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
    }
    &--parent {
        margin-top: 25px;
        margin-bottom: 27px;
    }

    &--card {
        background-color: variables.$white;
        padding: 19px 16px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        justify-content: space-between;
        &-canceled-duplicate {
            background-color: variables.$cancel-duplicate-background;
        }

        &-completed {
            background-color: rgb(89 175 110 / 20%);
        }

        &-follow-up {
            background-color: variables.$follow-up-background;
        }
    }

    &--card:last-child {
        margin-bottom: 0px;
    }

    &--check-client {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
    }

    &--no-visit {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: variables.$no-visit-background;
    }

    &--name {
        color: variables.$label-text-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }

    &--client-type-id {
        color: variables.$darker-grey;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }

    &--outcome {
        padding: 4px 15px;
        border-radius: 10px;
    }

    &--status-phone {
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: space-between;
    }

    &--outcome-text {
        color: variables.$target-list-status-text;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
    }

    &--desired-outcome-enrolled,
    &--desired-outcome-payment_2nd,
    &--desired-outcome-payment_3rd,
    &--desired-outcome-payment_finisher {
        background-color: variables.$village-client-border;
    }

    &--outcome-enrolled,
    &--outcome-card_payment,
    &--outcome-mobile_money_payment {
        background-color: variables.$green-interaction;
    }

    &--outcome-follow_up {
        background-color: variables.$orange-interaction;
    }

    &--outcome-not_interested,
    &--outcome-reimbursement_requested,
    &--outcome-cancel_package,
    &--outcome-duplicate {
        background-color: variables.$red-interaction;
    }

    &--spinner-parent {
        margin-top: 40px;
    }

    &--card-session {
        width: 100%;
    }

    &--search-input {
        height: 48px;
        border-radius: 40px;
        padding-top: 13px;
        padding-bottom: 13px;
        border-color: #16a34a80;
    }
}

.target--filter {
    &-parent {
        display: flex;
        gap: 15px;
        margin-top: 26px;
        justify-content: center;
    }

    &-button {
        padding: 7px 5px;
        border-radius: 11px;
        text-align: center;
        &-text {
            color: variables.$black;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
        }
        &-name {
            font-weight: 400;
            color: variables.$handshake-icon-color;
            font-size: 12px;
            text-align: center;
            font-style: normal;
            line-height: 18px;
        }
        &-name--selected {
            color: variables.$primary-color;
        }
    }
}

.search-input-container {
    margin-block: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
