@use '../../scss/abstract/variables';

.dual-village {
    width: 800px;

    @media (max-width: 540px) {
        width: auto;
    }

    &--section {
        margin-bottom: 22px;

        @media (max-width: 540px) {
            margin-bottom: 9px;
        }
    }

    &--header-parent {
        margin-top: 40px;
        margin-bottom: 16px;

        @media (max-width: 540px) {
            margin-top: 24px;
            margin-bottom: 8px;
        }
    }

    &--header {
        font-size: 19px;
        color: variables.$label-text-color;

        @media (max-width: 540px) {
            font-size: 16px;
        }
    }
}

.village-client-details-parent {
    margin-top: 40px;

    @media (max-width: 540px) {
        margin-top: 1px;
    }
}

.village-client-packet-parent {
    @media (max-width: 540px) {
        margin-top: 16px;
    }
}

.village-client-details-padding {
    padding: 4px 8px;

    @media (max-width: 540px) {
        padding: 0px;
    }
}

.client-packet {
    font-family: 'Work Sans', sans-serif;

    &--parent {
        border-bottom: 1px solid variables.$darker-grey-desktop;
        padding-bottom: 18px;

        &:last-child {
            border-bottom: 0px;
            padding-bottom: 0px;
        }
    }
    &--header {
        color: variables.$default-text;
        font-size: 32px;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
        margin-top: 20px;
    }

    &--body {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
    }

    &--main {
        flex: 0;
        font-size: clamp(1rem, 2.5vw, 1.188rem);
        color: variables.$label-text-color;
    }

    &--size-price {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: variables.$label-text-color;
    }

    &--size {
        font-size: clamp(0.875rem, 2.5vw, 1rem);
    }

    &--price {
        font-size: clamp(1rem, 2.5vw, 1.188rem);
        font-weight: 500;
    }

    &--btn-parent {
        max-width: 312px;
        margin: 0 auto;
    }

    &--delivery-btn {
        height: 40px;
        margin-top: 24px;
    }

    &--empty {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 20px;
    }

    &--no-packet {
        display: flex;
        align-items: center;
        border-radius: 16px;
        background: #fff;
        box-shadow: 3.372px 3.372px 8.431px 0px rgba(0, 0, 0, 0.1);
        padding: 40px;
        justify-content: center;
        width: 220px;
        text-align: center;
    }
}

.village-client-packet-header {
    color: #231f20;
    font-size: 32px;
    font-weight: 600;
    line-height: 150%;
    margin-top: 20px;
    text-align: center;
}

.village-client-payment-card {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .card-link {
        text-decoration: none;
    }
}

.village-client-sell-card {
    min-width: 312px;
    margin-bottom: 27px;
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;

    @media (max-width: 540px) {
        margin-top: 24px;
        margin-bottom: 0px;
    }

    @media (max-width: 320px) {
        min-width: auto;
        width: 100%;
    }

    .client-detail-card-sale-btn {
        height: 40px;
    }
}

.remove-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.village-client {
    &--body {
        margin-top: 24px;

        @media (max-width: 540px) {
            font-size: 16px;
        }

        & .client-contact-card {
            transform: scale(1);
            transition: 0.3s all ease-out;

            &:hover {
                transform: scale(1.02);
                transition: 0.15s all ease-in;
                background-color: variables.$village-client-border;
            }
        }

        & .client-contact-card-finisher {
            transform: scale(1);
            transition: 0.3s all ease-out;

            &:hover {
                transform: scale(1.02);
                transition: 0.15s all ease-in;
                background-color: variables.$village-client-border;
            }
        }
    }

    &--list {
        margin-bottom: 48px;

        .client-contact-card-finisher {
            background-color: variables.$online-color;
            border-bottom: 1px solid variables.$thin-black;
        }

        @media (max-width: 540px) {
            border: solid 1px variables.$village-client-border;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 19px;

            & > .client-contact-card {
                margin-bottom: 2px;
                border-radius: 0px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }

            & > .client-contact-card-finisher {
                margin-bottom: 0px;
                border-radius: 0px;
            }
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.village-card {
    cursor: pointer;
}

.client-payment-parent {
    margin-top: 40px;

    @media (max-width: 540px) {
        margin-top: 16px;
    }
}

.client-payment {
    font-family: 'Work Sans', sans-serif;

    &--header {
        color: variables.$default-text;
        font-size: 32px;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
        margin-top: 20px;
    }

    &--no-payments {
        color: variables.$default-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
        width: 50%;
        padding: 20px;
        margin: 0 auto 40px auto;
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
    }

    &--progress-container {
        margin: 20px 0;
        font-family: 'Work Sans', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--progress-header {
        font-size: 24px;
        font-weight: 600;
        line-height: 150%;
        color: variables.$grey;
        padding: 5px 0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &--progress-goal,
    &--progress-not-available {
        color: variables.$grey;

        svg {
            margin-right: 10px;
        }
    }

    &--progress-insufficient {
        color: variables.$red;

        svg {
            margin-left: 10px;
            transform: rotate(180deg);
        }
    }

    &--progress-negative-balance {
        color: variables.$red;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;

        svg {
            transform: rotate(180deg);
            margin-bottom: 5px;
        }
    }

    &--progress-surplus {
        color: variables.$primary-color;

        svg {
            margin-left: 10px;
        }
    }

    &--progress-finished {
        color: variables.$purple;
    }

    &--progress-bar-not-available {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 20px;
    }

    &--history-parent {
        font-size: clamp(0.975rem, 2.5vw, 1rem);
        color: variables.$card-payment-color;
    }

    &--history-section {
        margin-top: 14px;
        padding: 0px 0px 7px 0px;
        border-bottom: 1px solid variables.$darker-grey-desktop;

        &:last-child {
            border-bottom: none;
        }
    }
}

.payment {
    &--amount-card-id {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        align-items: center;
        margin-left: 20px;
        padding-block: 20px;
        font-size: clamp(1rem, 2.5vw, 1.125rem);
    }

    &--content {
        font-weight: 600;
        font-size: 18px;
    }

    &--amount {
        color: variables.$primary-color;
    }
    &--deducted-amount {
        color: variables.$error-red;
    }

    &--card-id {
        font-weight: lighter;
        font-size: 14px;
    }
    &--date {
        font-weight: 500;
    }
    &--secondary-icons {
        display: flex;
        gap: 4px;
    }
}

.village-client-payment-btn {
    margin-top: 50px;

    @media (max-width: 540px) {
        margin-top: 24px;
    }
}

.client-payment-history {
    &--header {
        text-transform: capitalize;
    }
}

.village-prev {
    &--client-card-details {
        .showMore {
            max-height: 100%;
        }
    }
}

.no-village {
    &--parent {
        color: variables.$offline-color-text;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        svg {
            width: 30px;
            height: 30px;
        }
    }

    &--text {
        font-size: clamp(1.125rem, 2.5vw, 1.25rem);
        width: max-content;
    }
}

.card-delivery {
    &--delivered {
        color: variables.$primary-color;
    }
    &--pre-certified {
        color: variables.$yellow-progress-bar;
    }

    &--status {
        font-size: clamp(0.75rem, 2.5vw, 0.875rem);
    }
}
.client-details--header {
    display: flex;
    justify-content: space-between;
}
.client-details--content {
    &--link {
        color: variables.$primary-color;
        font-size: clamp(0.87rem, 2.5vw, 1rem);
        margin-left: 5px;
        text-decoration: underline;
    }
}
.client-details--link {
    display: grid;
    grid-template-columns: repeat(2, 15%);
    align-items: center;
}

.visit-new-btn {
    height: 40px;
}

.visit-new-container {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.loading-wrapper {
    margin-top: 50px;
}

.partial-loader {
    &--text {
        font-size: 0.875rem;
        text-align: center;
        margin: auto;
        max-width: 300px;
    }
}

.scheduled-delivery-date {
    font-size: 16px;
    color: variables.$black;
}
