@use '../../scss/abstract/variables';

.metrics {
    &--summaries {
        margin-top: 45px;
        display: flex;
        justify-content: space-evenly;
        gap: 20px;
    }
    &--summary {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-circle {
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            box-shadow: 0px 2px 8px 5px #0000000d;
            margin-bottom: 15px;
        }
    }
    &--circle {
        &-inner-text {
            font-weight: 700;
            font-size: 13px;
        }
        &-text {
            font-weight: 500;
            color: variables.$default-text;
            font-size: 14px;
        }
    }
}
