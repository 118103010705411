@use '../components';

.packet-selection {
    background: components.$white;
    margin: 0px 20px;
    border-radius: 10px;

    &--section-header {
        width: 100%;
        height: 87px;
        background: components.$light-green-background repeat top left / 50% 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &--main {
        padding: 10px 20px;
    }

    &--heading {
        padding: 10px 0px;
        margin-bottom: 16px;
    }

    &--single-packet {
        padding: 5px;
        margin-bottom: 16px;
        max-width: 140px;
    }

    &--heading {
        text-align: center;

        &-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
        }

        &-subtitle {
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
        }
    }
}

.payment-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex-grow: 1;
}

.payment-methods-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.payment-method-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    padding: 10px;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 15px;
    border: 1px solid components.$primary-color;
    background: white;

    &--selected {
        background: components.$light-green-background;
        color: white;
    }

    p {
        text-align: center;
        font-weight: 500;
        font-family: 'Work Sans';
        color: black;
    }
}

.payment-progress {
    height: 26px;
    width: 100%;
    max-width: 267px;
    border-radius: 5px;
    background-color: components.$divide-left-color;
}
.payment-total {
    font-family: 'Work Sans';
    color: black;
    font-size: 14px;
    margin-top: 10px;
    padding-left: 10px;
    width: 100%;
    max-width: 267px;
}
.payment-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
}

b {
    font-weight: bold;
}
