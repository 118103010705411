@use '../../../scss/abstract/variables';

.fc-home {
    &--welcome {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 30px;
        font-weight: 400;
        color: variables.$default-text;
        text-transform: capitalize;
    }
    &--search {
        margin-top: 45px;
    }
}
