@mixin font-basic() {
    font-family: 'Work Sans', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
}

p,
h1,
h2,
h3,
label,
option,
input[type='text'],
input[type='password'],
input[type='number'],
select,
a {
    @include font-basic();
}

.header-welcome {
    font-family: 'Zilla Slab', serif;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
}
