@use '../../scss/abstract/variables';

.login-top-section {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.logo-parent {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;

    .login-welcome {
        margin-top: 8px;
        font-family: 'Work Sans';
        font-size: 21px;
        font-style: italic;
        text-align: center;
        color: variables.$primary-color;

        @media (max-width: 420px) {
            font-size: 19px;
        }
    }
}

.login-input {
    height: 50px;
    border-radius: 10px;
    border: none;
    border-bottom: 1px solid variables.$primary-color;
    background-color: white;

    &--valid {
        @extend .login-input;
        border: 1px solid variables.$primary-color;
    }

    &--invalid {
        @extend .login-input;
        border: 1px solid #ff1515;
    }
}

.forgot-password {
    margin-top: 4px;
}

.login-button {
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 5px;
    width: 450px;
    height: 75px;
    font-size: 20px;

    @media (max-width: 540px) {
        width: 300px;
    }

    &.disabled-btn {
        background-color: #d3d3d3;
    }
}

.training-toggle {
    width: 140px;
}

.google-btn {
    background-color: variables.$white;
}

.google-btn > span {
    margin-left: 10px;
}

.fc-section {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 150px;
    padding: 10px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: variables.$light-grey;
    margin-top: auto;
}

.fc-title {
    color: variables.$darker-grey;
    font-family: 'Work Sans';
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
}

.login-container {
    display: flex;
    flex-direction: column;
}

.login-error {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 287px;
    padding: 10px;
    border: 1px solid #ff7575;
    background: #ffcbcb;
    color: #000;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.login-form {
    width: 450px;

    @media (max-width: 540px) {
        width: 300px;
    }
}
