@use '../../scss/abstract/variables';

.toggle-parent {
    width: 100%;
}

.toggle-input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle-input:focus {
    outline: none;
}

.toggle-label {
    cursor: pointer;
    text-indent: 35px;
    width: 100%;
    height: 40px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    transition: 0.35s;
}

.toggle-label:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 100%;
    transition: 0.3s;
}

.toggle-input:checked + .toggle-label {
    text-indent: calc(100% - 90px);
}

.toggle-input:checked + .toggle-label:after {
    left: calc(100% - 5px);
    top: 50%;
    transform: translate(-100%, -50%);
}

.toggle-label:active:after {
    width: 35px;
}

.toggle-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 9px;
    right: 0px;
    overflow: hidden;
    color: variables.$white;
    font-size: clamp(0.875rem, 2.5vw, 1rem);

    @media (max-width: 420px) {
        left: 12px;
    }
}

.toggle-input:checked + .toggle-label .toggle-text {
    right: 38px;

    @media (max-width: 420px) {
        right: 30px;
    }
}

.toggle-training-on label::after {
    background-color: variables.$training-bg-color;
}

.toggle-training-off label::after {
    background-color: variables.$primary-color;
}

.toggle-training-on label {
    background: white;
}

.toggle-training-off label {
    background: #d9d9d9;
}

.toggle-training-on p {
    color: variables.$training-bg-color;
}

.toggle-training-off p {
    color: variables.$primary-color;
}
