@use '../components';
@use '../../scss/abstract/variables';

.summary-container {
    border-bottom: 1px solid #d9d9d9;
    height: 80px;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.summary-title {
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: variables.$grey;
}

.summary-numbers-container {
    display: flex;
    flex-direction: horizontal;
    justify-content: start;
    padding-left: 27%;
    margin-top: 5px;
    gap: 10px;
    width: 100%;

    @media (max-width: 500px) {
        padding-left: 25%;
    }
    @media (max-width: 450px) {
        padding-left: 22%;
    }
    @media (max-width: 400px) {
        padding-left: 20%;
    }
    @media (max-width: 350px) {
        padding-left: 5%;
    }
}

.summary-nb-cards {
    font-size: 20px;
    font-weight: 600;
    width: 50px;
    height: 40px;
    border-radius: 30px;
    color: variables.$white;
    display: flex;
    justify-content: center;
    padding: 5px;
}

.summary-amount {
    font-size: 24px;
    font-weight: 600;
    color: variables.$grey;
}
