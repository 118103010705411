// ABSTRACTS
@use 'scss/abstract/mixins';
@use 'scss/abstract/variables';

// BASES
@use 'scss/base/reset';
@use 'scss/base/typography';

// GENERAL
:root {
    --app-background: variables.$body-bg-color;
    --client-subscribed-color: variables.$yellow-progress-bar;
    --primary-color: variables.$primary-color;
}

body {
    background-color: var(--app-background);

    .clear-h-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .flex-0 {
        flex: 0;
    }

    .flex-1 {
        flex: 1;
    }

    .content-center {
        justify-content: center;
    }
}

.align-items-center {
    align-items: center;
}
.justify-content-center {
    justify-content: center;
}

.page-wrapper {
    margin-bottom: 100px;
}

.form-label {
    font-size: 16px;
    margin-bottom: 4px;
    display: inline-block;
    color: variables.$label-text-color;
}

.form-label.required {
    &::after {
        content: '*';
        color: variables.$label-text-color;
        margin-left: 5px;
    }
}

.form-label.required.error {
    &::after {
        color: variables.$red;
    }
}

.display-flex {
    display: flex;
}

.space-btw {
    justify-content: space-between;
}

.mt-auto {
    margin-top: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.fd-col {
    flex-direction: column;
}

.height-100 {
    height: 100%;
}

.width-100 {
    width: 100%;
}

.uppercase {
    text-transform: uppercase;
}

.ai-fe {
    align-items: flex-end;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    background-image: url('/public/images/dropdown-icon.svg');
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 50%;
}

.header-bar {
    background-color: variables.$white;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.header-bar--transparent {
    background-color: transparent !important;
}

.header-bar-shadow {
    box-shadow: 1px 4px 19px -7px rgb(0 0 0 / 12%);
    background: variables.$white !important;
}

.tellPhone-link {
    color: variables.$primary-color;
    text-decoration: none;
}

.phone-number-text {
    font-weight: 600;
    &--follow_up {
        text-decoration: none;
        color: variables.$follow-up-interaction-btn-text-color;
    }
    &--not_interested {
        text-decoration: none;
        color: variables.$not-interested-interaction-btn-text-color;
    }
    &--default {
        text-decoration: none;
        color: variables.$default-interaction-btn-text-color;
    }
    &--duplicate {
        text-decoration: none;
        color: variables.$darker-grey;
    }
    &--normal {
        text-decoration: none;
        color: #16a34a;
    }
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}
.responsive-btn-font {
    font-size: clamp(1rem, 2vw, 1.25rem);
}

/**
 * ----------------------------------------
 * animation transition-left-2
 * ----------------------------------------
 */

@keyframes transition-left-2 {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 0% -50%;
        transform-origin: 0% -50%;
    }

    100% {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transform-origin: -50% 0%;
        transform-origin: -50% 0%;
    }
}
@keyframes transition-right-1 {
    0% {
        -webkit-transform: translateX(-67%);
        transform: translateX(-67%);
        -webkit-transform-origin: -34% 0%;
        transform-origin: -34% 0%;
    }

    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 0% -50%;
        transform-origin: 0% -50%;
    }
}

/**
 * ----------------------------------------
 * animation slide-left-step-2
 * ----------------------------------------
 */
@keyframes slide-left-2 {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 0% -33%;
        transform-origin: 0% -33%;
    }
    100% {
        -webkit-transform: translateX(-33%);
        transform: translateX(-33%);
        -webkit-transform-origin: -33% 0%;
        transform-origin: -33% 0%;
    }
}
/**
 * ----------------------------------------
 * animation slide-left-step-3
 * ----------------------------------------
 */
@keyframes slide-left-3 {
    0% {
        -webkit-transform: translateX(-33%);
        transform: translateX(-33%);
        -webkit-transform-origin: -66% 0%;
        transform-origin: -66% 0%;
    }
    100% {
        -webkit-transform: translateX(-66%);
        transform: translateX(-66%);
        -webkit-transform-origin: -66% 0%;
        transform-origin: -66% 0%;
    }
}
/**
 * ----------------------------------------
 * animation slide-right-step-2
 * ----------------------------------------
 */
@keyframes slide-right-2 {
    0% {
        -webkit-transform: translateX(-67%);
        transform: translateX(-67%);
        -webkit-transform-origin: -34% 0%;
        transform-origin: -34% 0%;
    }
    100% {
        -webkit-transform: translateX(-33%);
        transform: translateX(-33%);
        -webkit-transform-origin: -33% 0%;
        transform-origin: -33% 0%;
    }
}
/**
 *
 * ----------------------------------------
 * animation slide-right-step-1
 * ----------------------------------------
 */
@keyframes slide-right-1 {
    0% {
        -webkit-transform: translateX(-33%);
        transform: translateX(-33%);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }
}

.generic-enter,
.generic-appear {
    opacity: 0;
    transform: translateY(100px);
}

.generic-enter-active,
.generic-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 700ms, transform 700ms;
}

@include mixins.createAnimateClass(12);

.version--text {
    color: variables.$divide-left-color;
}

.client-details-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 5px;
    font-size: clamp(0.875rem, 2.5vw, 1rem);

    &--header-title {
        color: variables.$card-payment-color;
    }

    &--edit {
        display: flex;
        column-gap: 5px;
        border-bottom: 1px solid variables.$primary-color;
        color: variables.$primary-color;
        align-items: center;
        cursor: pointer;
    }
}

.generic {
    &--error-message {
        color: variables.$offline-color-text;
        font-size: clamp(0.75rem, 2.5vw, 0.875rem);
        text-align: center;
    }
    &--error-border {
        border-color: variables.$faded-red !important;
    }
}

.form-parent {
    max-width: 794px;
    margin: 0 auto;
    margin-top: 40px;

    .formgroup {
        margin-top: 24px;

        &:first-child {
            margin-top: 0px;
        }

        @media (max-width: 420px) {
            margin-top: 16px;
        }
    }

    label {
        margin-bottom: 4px;
        display: block;
        color: variables.$label-text-color;
        font-size: 16px;
    }

    @media (max-width: 420px) {
        margin-top: 33px;
    }
}

.card-client-abbr {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    border: solid 1px #e4e4e7;
    background-color: #f2f2f2;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #6b6b6b;
    flex-shrink: 0;

    &--name {
        width: 64px;
        height: 64px;
        font-size: 16px;

        @media (max-width: 540px) {
            width: 56px;
            height: 56px;
        }
    }

    &--low {
        background-color: variables.$offline-color-text;
    }

    &--medium {
        background-color: variables.$yellow-progress-bar;
    }

    &--high {
        background-color: variables.$primary-color;
    }
}

.loading-text {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
}

.qr-code-button {
    &--parent {
        width: 70px;
        height: 70px;
        background: variables.$primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        top: -50px;
        left: -33px;
    }
}

.menu {
    &--unread-notification-dot {
        border-radius: 50%;
        height: 10px;
        position: absolute;
        right: -5px;
        top: -5px;
        width: 10px;
        background: variables.$deep-red;
    }

    &--notification-icon {
        position: relative;
    }
}
