@use '../../scss/abstract/variables';

.client-search {
    &--main-parent {
        margin-top: 12px;
    }

    &--form-parent {
        margin-top: 20px;
    }

    &--or-parent {
        margin: 30px 0px;
    }

    &--or-divider {
        display: flex;
        flex-direction: row;
    }
    &--or-divider:before,
    &--or-divider:after {
        content: '';
        flex: 1 1;
        border-bottom: 1px solid variables.$divide-left-color;
        margin: auto;
    }
    &--or-divider:before {
        margin-right: 10px;
    }
    &--or-divider:after {
        margin-left: 10px;
    }

    &--result-containter {
        padding-bottom: 50px
    }
}

.card-search {
    &--parent {
        display: flex;
        gap: 20px;
        align-items: center;
        background-color: variables.$white;
        border-radius: 8px;
        padding: 10px 8px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    &--parent:last-child {
        margin-bottom: 0px;
    }

    &--page-title {
        margin: 12px 0px;
    }

    &--title {
        font-family: 'Work Sans';
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
    }

    &--full-name {
        flex: 1;
        font-size: 16px;
        color: variables.$label-text-color;
        font-weight: 600;
    }

    &--other-content {
        flex: 1;
        font-size: 14px;
        color: variables.$card-payment-color;
    }

    &--add-new-client {
        margin-top: 40px;
        margin-bottom: 120px;
    }
}

.add-new-client-link {
    text-decoration: underline;
    color: variables.$primary-color;
    text-align: center;
    cursor: pointer;
}

.next-search-button {
    margin: 0 auto;
    margin-top: 48px;
}

.no-result {
    text-align: center;
    margin-top: 40px;
    font-size: 16px;
    color: variables.$offline-color-text;
}
