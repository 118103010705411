@use '../components';

.counter-pill {
    background-color: #186d2a;
    height: 25px;
    display: flex;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;
    flex-grow: 0;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: components.$white;
    font-family: 'Work Sans', sans-serif;
    width: 100%;
}

.incremental-btn {
    margin-right: 10px;
    margin-left: 10px;
}

.counter-pill-left-container {
    width: '40%';
    justify-content: 'flex-start';
    display: 'flex';
}
.counter-pill-right-container {
    width: '40%';
    justify-content: 'flex-end';
    display: 'flex';
}
