@use '../../scss/abstract/variables';

.circular-progressbar {
    &--parent {
        border-radius: 50%;
        color: variables.$white;
        background-color: variables.$body-bg-color;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        position: relative;
        margin-bottom: 20px;
    }

    &--label {
        position: absolute;
        text-align: center;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--complete {
        color: variables.$white;
    }

    &--incomplete {
        color: variables.$default-text;
    }
}

.circular-progressbar-container {
    display: flex;
    flex-direction: row;
}

.milestones-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 4%;
    padding-top: 8%;
    padding-bottom: 8%;
}
