@use '../../scss/abstract/variables';

.number-input-field {
    display: block;
    margin: 2em auto;
    border: none;
    padding: 0;
    width: var(--in-w);
    background: variables.dotted-line(variables.$primary-color);
    font-size: 3ch;
    font-variant-numeric: tabular-nums !important;
    font-weight: 600 !important;
    letter-spacing: var(--gap) !important;
}

/* Hide arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}