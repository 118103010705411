@use '../components';

body {
    --animate: all 0.5s;
}

.stats {
    display: flex;
    align-items: center;
    font-size: clamp(0.831rem, 2.5vw, 1rem);
}

.stats--red {
    color: #b41d09;
    transition: var(--animate);
}

.stats--green {
    color: components.$primary-color;
    transition: components.$animate;
}

.stats--yellow {
    color: components.$yellow-progress-bar;
    transition: components.$animate;
}

.stats-icon {
    margin-right: 2px;
    display: flex;
}

.stats-icon svg {
    width: 14px;
    height: 14px;
}

.stats-icon--red svg rect {
    fill: #b41d09;
    transition: components.$animate;
}

.stats-icon--green svg rect {
    fill: components.$primary-color;
    transition: components.$animate;
}

.stats-icon--up svg {
    transform: rotateZ(0deg);
    transition: components.$animate;
}

.stats-icon--down svg {
    transform: rotateZ(180deg);
    transition: components.$animate;
}
