@use 'sass:math';
@use './variables';

$media-sizes: (
    '576px': 540px,
    '768px': 720px,
    '992px': 960px,
    '1200px': 1140px,
    '1400px': 1320px
);

$media-breakpoint: (
    'sm': 576px,
    'md': 768px,
    'lg': 992px,
    'xl': 1200px,
    'xxl': 1400px
);
@mixin container-media() {
    @each $size, $media-size in $media-sizes {
        @media (min-width: $size) {
            max-width: $media-size;
        }
    }
}
// $size, $col, $fluid-size

// create mobile cols
@mixin mobile-cols($col) {
    @for $i from 1 through $col {
        .col-#{$i} {
            -webkit-box-flex: 1;
            -ms-flex: 0 0 percentage(math.div(1, math.div($col, $i)));
            flex: 0 0 percentage(math.div(1, math.div($col, $i)));
            max-width: percentage(math.div(1, math.div($col, $i)));
        }
    }
}

@mixin create-col($col) {
    @each $md-br, $md-val in $media-breakpoint {
        @for $i from 1 through $col {
            .col-#{$md-br}-#{$i} {
                position: relative;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
            }
        }
        @media (min-width: $md-val) {
            @for $i from 1 through $col {
                .col-#{$md-br}-#{$i} {
                    flex: 0 0 percentage(math.div(1, math.div($col, $i)));
                    max-width: percentage(math.div(1, math.div($col, $i)));
                }
            }
        }
    }
}

@mixin createAnimateClass($quantity) {
    $startTransform: 30;
    $startTransition: 700;
    @for $i from 1 through $quantity {
        .animate-show-#{$i} {
            transform: translateY($startTransform + px);
        }
        .animate-showed-#{$i} {
            transform: translateY(0px);
            transition: $startTransition + ms;
        }
        $startTransform: $startTransform + 35;
        $startTransition: $startTransition + 100;
    }
}

@mixin flow-styles($color) {
    .steps--tail-active {
        background: $color;
    }

    .steps--head-active {
        background: $color;
    }

    .client-details--bottom-button {
        background: $color;
    }

    .enrollment--phone-icon svg {
        fill: $color;
    }

    .enrollment--phone-icon svg path {
        stroke: $color;
    }

    .enrollment--input-number {
        background: variables.dotted-line($color);
    }
}
