@use '../components';
@use '../../scss/abstract/variables';

.card-parent {
    width: 100%;
    padding: 12px 16px;
    border-radius: 20px;
    box-shadow: 1px 4px 12px 0 rgba(133, 124, 124, 0.06);
    background-color: variables.$white;
}

.no-shadow {
    box-shadow: none;
}

.card-client-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    .card-client-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        @media (max-width: 425px) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            gap: 1px;
        }
    }
}

.card-client-text-color {
    color: components.$card-payment-color;
}

.card-client--no-spread {
    justify-content: initial;
}

.card-client-abbr--small {
    width: 56px;
    height: 56px;
    font-size: 16px;
}

.card-client-abbr--large {
    width: 64px;
    height: 64px;
}

.card-client-abbr-payment--small {
    width: 48px;
    height: 48px;
    font-size: 12px;

    @media (max-width: 540px) {
        width: 48px;
        height: 48px;
    }
}

.card-client-data {
    margin-left: 10px;
    font-family: 'Work Sans', sans-serif;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;

    .card-client-name {
        font-size: clamp(0.875rem, 2.5vw, 1rem);
        font-weight: 600;
        color: #2f2f20;
        text-transform: capitalize;
    }

    .card-client-id {
        font-size: 14px;
        color: #6b6b6b;
    }

    .card-client-id--client {
        font-size: clamp(0.875rem, 2.5vw, 1rem);
        color: components.$card-payment-color;
    }

    .card-client-id--payment {
        font-size: clamp(0.75rem, 2.5vw, 0.875rem);
        color: components.$card-payment-color;
        margin-right: 4px;
        text-transform: capitalize;
    }

    .card-client-phone {
        font-size: 19px;
        font-weight: 600;
        text-align: left;
        color: components.$card-payment-color;
        margin-right: 4px;

        &--icon {
            display: flex;
            align-items: center;

            & svg path {
                stroke: components.$card-payment-color;
                opacity: 0.7;
            }
        }

        @media (max-width: 540px) {
            font-size: 16px;
        }
    }

    &--name {
        font-size: 19px;
        font-weight: 600;
        color: components.$label-text-color;

        @media (max-width: 540px) {
            font-size: 16px;
            text-transform: capitalize;
        }
    }
}

.card-client-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 540px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.card-pipe {
    border-right: 1px solid #979797;
    display: inline-block;
    margin: 0px 4px;
    height: 14px;
}

.card-phone-icon {
    display: flex;
    align-items: center;
    min-width: 115px;

    @media (max-width: 540px) {
        min-width: initial;
    }

    .card-phonenumber {
        margin-right: 4.5px;
        font-weight: 600;
        color: components.$card-payment-color;
        font-size: clamp(0.75rem, 2.5vw, 0.875rem);

        @media (max-width: 540px) {
            font-weight: 500;
        }
    }
}

.display-mobile {
    .card-client-payment {
        display: none;

        @media (max-width: 540px) {
            display: flex;
            column-gap: 4px;
            justify-content: flex-start;
        }
    }
}

.card-client-action {
    font-size: 12px;
    font-weight: 500;
    color: components.$card-payment-color;
}

.client-card-payment--label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #6b6b6b;
}

.client-card-payment--label-completed {
    color: components.$primary-color;
    font-weight: 600;
}

.card-client-status {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
}

.client-card--red {
    color: components.$offline-color-text;
}

.client-card--green {
    color: components.$lemon-green;
}

.client-card--good {
    color: components.$primary-color;
}

.commission-duration-parent {
    font-size: 19px;
    font-weight: 600;
    color: components.$home-card-black;

    .commission-duration {
        font-weight: normal;
        color: components.$light-black;
    }

    @media (max-width: 420px) {
        font-size: 16px;
    }
}

.commission-amount {
    font-size: 28px;
    font-weight: bold;
    color: components.$dark-green;

    @media (max-width: 420px) {
        font-size: 23px;
    }
}

.card-client-name-abbr {
    display: flex;
    align-items: center;
    min-width: 245px;

    @media (max-width: 420px) {
        min-width: initial;
    }

    @media (max-width: 943px) {
        min-width: 0px;
    }
}

.card-client-payment {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: components.$card-payment-color;
    flex-wrap: wrap;
    font-size: clamp(0.75rem, 2.5vw, 0.875rem);
    column-gap: 10px;

    @media (max-width: 540px) {
        display: none;
    }
}

.card-payment-amount-date {
    .payment-amount-date {
        font-weight: 600;
    }

    .completed-payment {
        color: components.$primary-color;
    }
}

.card-payment-left {
    font-size: clamp(0.71rem, 2.1vw, 0.871rem);

    .amount-left {
        color: components.$primary-color;
    }
}

.card-pipe-left {
    position: relative;
    padding-left: 4px;

    &::before {
        position: absolute;
        content: '';
        height: 60%;
        border-left: 1px solid components.$divide-left-color;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.card-sales-parent {
    max-width: 123px;
    margin: auto;
    color: components.$label-text-color;
    position: relative;
    cursor: pointer;

    @media (max-width: 540px) {
        max-width: 96px;
        margin: auto;
    }

    .card-sales--bold {
        font-weight: bold;
    }

    .card-sales {
        border-radius: 13px;
        text-align: center;
        padding-top: 35px;
        font-size: 20.7px;
        color: components.$black;
        position: relative;

        @media (max-width: 540px) {
            font-size: 16px;
            padding-top: 27px;
        }

        &--price {
            transform: translateY(0px);
            transition: components.$animate;
        }

        &--logo-parent {
            height: 72px;
            overflow: hidden;
            border-bottom-left-radius: 13px;
            border-bottom-right-radius: 13px;
            position: relative;

            @media (max-width: 540px) {
                height: 56px;
            }
        }

        .selected-number {
            position: absolute;
            z-index: 10;
            width: 108px;
            background: black;
            color: variables.$white;
            border-radius: 60px;
            bottom: 15px;
            padding: 6px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 21px;
            transition: components.$animate;

            &--content {
                font-weight: bold;
            }

            @media (max-width: 540px) {
                width: 83px;
                font-size: 17px;
            }
        }

        &--logo {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 10px;

            & > * {
                z-index: 9;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                background-color: components.$card-sale-black;
                width: 100%;
                height: 100%;
                border-top-right-radius: 13px;
                transform: skewY(14deg);
                transform-origin: -2% 100%;
                z-index: 1;

                @media (max-width: 540px) {
                    transform: skewY(11deg);
                }
            }
        }

        &:after {
            min-height: 138px;
            content: '';
            position: absolute;
            background: #eceded;
            top: 0px;
            left: 0px;
            right: 0px;
            z-index: -1;
            bottom: 0px;
            border-radius: 13px;
            transition: components.$animate;

            @media (max-width: 540px) {
                min-height: 107px;
            }
        }
    }

    .card-sales-out {
        background: components.$rgba-mid-black;
        color: components.$mid-black;
    }

    .card-sales-selected {
        &:after {
            top: -18px;
            background-color: components.$rgba-black;
            box-shadow: 0 4px 10px 0 components.$divide-color;
        }

        .card-sales--price {
            transform: translateY(-10px);
            transition: components.$animate;
        }
    }

    .card-sales-label {
        text-align: center;
        margin-top: 8px;
        line-height: 1.5;

        &--stock {
            font-size: 14px;

            @media (max-width: 540px) {
                font-size: 10px;
            }
        }

        &--quantity {
            font-size: 19px;

            @media (max-width: 540px) {
                font-size: 14px;
            }
        }
    }

    .card-sales-no-card {
        background: components.$orange;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -21px;
        right: 0px;

        @media (max-width: 540px) {
            width: 32px;
            height: 32px;
        }
    }

    .selected-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid variables.$white;
        position: absolute;
        top: -34px;
        right: -16px;
        transition: components.$animate;

        @media (max-width: 540px) {
            width: 24px;
            height: 24px;
            top: -29px;
            right: -10px;
        }
    }
}

.card-village-parent {
    @media (max-width: 540px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 0px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 6px 0px;
}

.card-village {
    &--name {
        @media (max-width: 540px) {
            flex: 0.7;
            border-bottom: none;
            width: initial;
            text-align: left;
            padding-bottom: 0px;
        }

        color: components.$primary-color;
        border-bottom: 1px solid components.$darker-grey-desktop;
        width: 100%;
        text-align: center;
        padding-bottom: 11px;
    }

    &--name-only {
        border-bottom: 0px;
        padding-bottom: 0px;

        @media (max-width: 540px) {
            flex: 0;
        }
    }

    &--village {
        font-weight: 500;
        font-size: 19px;

        @media (max-width: 540px) {
            font-size: 16px;
        }
    }

    &--count {
        display: flex;
        justify-content: space-between;
        flex: 1;

        position: relative;

        width: 100%;
        max-width: initial;
        margin-top: 12px;

        @media (max-width: 540px) {
            max-width: 155px;
            margin-top: 0px;
            padding-left: 12px;
        }

        &-section {
            text-align: center;
            flex: 1;
            position: relative;

            &:first-of-type {
                &:after {
                    content: '';
                    position: absolute;
                    height: 80%;
                    width: 1px;
                    top: 50%;
                    transform: translateY(-50%);
                    bottom: 0px;
                    right: 0px;
                    background: components.$darker-grey-desktop;

                    @media (max-width: 540px) {
                        content: none;
                    }
                }
            }
        }

        &-header {
            font-size: 14px;
            font-weight: 500;
            color: components.$darker-grey;

            @media (max-width: 540px) {
                font-size: 12px;
            }
        }

        &-number {
            font-size: 19px;
            font-weight: 500;
            color: components.$label-text-color;

            @media (max-width: 540px) {
                font-size: 14px;
            }
        }

        &:after {
            content: none;
            position: absolute;
            height: 80%;
            width: 1px;
            top: 50%;
            transform: translateY(-50%);
            bottom: 0px;
            left: 0px;
            background: components.$darker-grey;

            @media (max-width: 540px) {
                content: '';
            }
        }
    }
}

.card-client-detail {
    &--top {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        margin-top: 16px;

        @media (max-width: 540px) {
            padding-left: 0px;
            padding-right: 0px;
            margin-top: 0px;
        }

        .name-abr-id {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            row-gap: 10px;
        }
    }

    &--bottom {
        padding: 0px 8px;
        padding-top: 24px;
        margin-top: 23px;
        place-items: center;

        @media (max-width: 540px) {
            padding: 0px;
            padding-top: 16px;
            margin-top: 15px;
        }

        .client-other-single {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 16px;

            &:last-child {
                margin-bottom: 7px;
            }

            .client-other-key {
                color: components.$label-text-color;
            }

            .client-other-value {
                color: components.$black;
                font-weight: 500;
            }
        }
    }

    &--modify {
        position: absolute;
        right: 16px;
        top: -10px;

        @media (max-width: 540px) {
            position: relative;
            left: 0px;
            display: block;
            text-align: right;
            top: 0px;
        }
    }

    &--icons {
        padding-block: 10px;
    }

    &--name-icon {
        display: flex;
        align-items: center;
    }

    &--name {
        font-size: 19px;
        font-weight: 600;
        color: components.$home-card-black;
        margin-bottom: 2px;
        margin-right: 6px;
        text-transform: capitalize;

        @media (max-width: 540px) {
            font-size: 16px;
            margin: 0px auto;
        }
    }

    &--id {
        font-size: 16px;
        color: components.$darker-grey;
        margin-bottom: 16px;

        @media (max-width: 540px) {
            font-size: 14px;
        }
    }

    &--contact-interactions {
        display: flex;
        width: 50%;
        margin: 25px auto;
    }

    &--button {
        $buttonSelector: &;
        flex: 1;
        height: 32px;
        font-size: clamp(10px, 3vw, 14px);
        font-weight: 500;
        border-radius: 4px;
        width: 50%;
        justify-content: space-evenly;

        @media (max-width: 540px) {
            font-size: 14px;
        }
        &--hide {
            @extend #{$buttonSelector};
            flex: 0.5;
            margin: auto;
        }
    }

    &--button-interactions {
        @extend .card-client-detail--button;
        border-radius: 4px;
        height: 42px;
        width: 95%;
        margin: auto;
        margin-top: 15px;
        justify-content: center;
        padding: 30px;
    }

    &--client-phone {
        @extend .card-client-detail--button;
        border-radius: 10px;
        padding: 20px;
    }
    &--client-phone-duplicate {
        @extend .card-client-detail--button;
        border-radius: 10px;
        padding: 20px;
        background-color: #d9d9d9;
    }
    &--empty-icon {
        padding-block: 10px;
        width: 56px;
        height: 56px;
        margin: 0 auto;
        border-radius: 60px;
        background: components.$no-visit-background;
    }
}

.client-phone {
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    margin: 0 auto;
}
.client-full-details {
    display: grid;
    gap: 5px;
    &--age-group-role-container {
        display: flex;
        justify-content: space-between;
        width: 30%;
        margin: 0 auto;
    }
    &--age-group-role {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 24px;
        border: 1px solid components.$black;
        background: components.$white;
    }
    &--empty-age-group {
        @extend.card-client-detail--empty-icon;
        width: 40px;
        height: 40px;
        border-radius: 24px;
    }
    &--age-group-text {
        font-size: 12px;
    }
}

.client-detail-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    gap: 15px;
    &--empty-avatar {
        @extend .card-client-detail--empty-icon;
        margin: 0;
    }
}

.interactions-container {
    width: 90%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

.interactions-container--show {
    @extend .interactions-container;
    max-height: 300px;
}

.interactions-container--hide {
    @extend .interactions-container;
    max-height: 0;
}

.card-client-detail-abr {
    width: 64px;
    height: 64px;
    font-size: 19px;
    margin-bottom: 16px;

    @media (max-width: 540px) {
        width: 56px;
        height: 56px;
        font-size: 16px;
        margin-right: 10px;
        margin-bottom: 12px;
    }
}

.view-more {
    border-top: 1px solid components.$darker-grey-desktop;
    padding: 13px 0px 1px;
    text-align: center;
    margin-top: 15px;
    display: none;
    font-size: 14px;

    @media (max-width: 540px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-up {
        transition: all 0.5s ease-out;
        transform: rotate(0deg);
        display: flex;
    }

    .icon-down {
        transition: all 0.5s ease-out;
        transform: rotate(180deg);
        display: flex;
    }

    .close-display {
        color: components.$primary-color;
    }
}

.hideMore {
    @media (max-width: 540px) {
        max-height: 0px;
        overflow: hidden;
        transition: all 0.35s ease-out;
        border-top: 0px;
        padding-top: 0px;
        margin-top: 0px;
    }
}

.showMore {
    max-height: 400px;
    transition: all 0.4s ease-in;
    overflow: hidden;
}

.registered-progressbar {
    &--parent {
        flex: 0.3;
        border-left: 1px solid components.$darker-grey-desktop;

        & .registered-progressbar--stats {
            font-size: 0.75rem;
        }
    }

    &--label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        color: components.$label-text-color;
    }

    &--target-result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: components.$home-card-black;

        .result {
            font-size: clamp(1rem, 2.5vw, 1.125rem);
            font-weight: bold;
        }

        .target {
            &--parent {
                display: flex;
                font-size: clamp(0.875rem, 2.5vw, 1em);
                font-weight: bold;

                .target {
                    &--content {
                        margin-left: 5px;
                        font-weight: bold;
                    }

                    &--of {
                        color: components.$mid-black;
                    }
                }
            }
        }
    }
}

.show-mobile {
    display: none;

    @media (max-width: 540px) {
        display: block;
    }
}

.hide-mobile {
    display: block;

    @media (max-width: 540px) {
        display: none;
    }
}

.card-summary-item {
    padding: 0px 8px;
    padding-top: 18px;
    margin-top: 20px;
    border-top: 1px solid components.$darker-grey-desktop;
}

.card-summary-single {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    font-size: 16px;
}

.card-summary-other-key {
    color: components.$label-text-color;
    margin-right: 10px;
}

.card-summary-other-value {
    color: components.$black;
    font-weight: 500;
    max-width: 150px;
    text-align: right;
}

.card-summury-content {
    .card-summary-item:first-child {
        margin-top: 10px;
    }
}

.card-summary-product-price {
    margin-left: 24px;
    font-weight: bold;
}

.card-summary-header {
    display: flex;

    .card-summary-top-link {
        margin-left: auto;
        cursor: pointer;
    }
}

.payment-color {
    &--red {
        color: components.$offline-color-text;
    }

    &--green {
        color: components.$primary-color;
    }

    &--yellow {
        color: components.$yellow-progress-bar;
    }
}

.card-delivery {
    &--delivered {
        color: components.$primary-color;
    }

    &--pre-certified {
        color: components.$yellow-progress-bar;
    }
}

.card-client-status {
    $status: &;
    text-align: center;
    margin: 5px 0;
}

.card--product-parent {
    padding-right: 10px;
    padding-top: 10px;
}

.card-product {
    width: 140px;
    height: 140px;
    border-radius: 13px;
    border-width: 1px;
    border-style: solid;
    border-color: components.$product-card-border;
    background-color: components.$light-green-background;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &--selected {
        border-color: components.$very-dark-green;
        border-width: 7px;

        .card-product-picture {
            top: 0;
            left: 0;
            width: 126px;
            height: 126px;
            border-radius: 7px;

            @media (max-width: 370px) {
                width: 116px;
                height: 116px;
            }
            @media (max-width: 345px) {
                width: 106px;
                height: 106px;
            }
        }

        .card-product-name {
            position: absolute;
            bottom: 39;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            width: 126px;

            @media (max-width: 370px) {
                width: 116px;
            }
            @media (max-width: 345px) {
                width: 106px;
            }
        }
    }
    position: relative;
    @media (max-width: 370px) {
        width: 130px;
        height: 130px;
    }
    @media (max-width: 345px) {
        width: 120px;
        height: 120px;
    }
}

.card-product-name {
    background-color: variables.$white;
    font-family: 'Work Sans', sans-serif;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    width: 138px;
    height: 39px;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border-top: 1px solid variables.$thin-black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 39;

    @media (max-width: 370px) {
        font-size: 0.7rem;
        width: 128px;
    }

    @media (max-width: 345px) {
        font-size: 0.65rem;
        width: 118px;
    }
}

.card-product-picture {
    width: 138px;
    height: 138px;
    border-radius: 13px;

    @media (max-width: 370px) {
        width: 128px;
        height: 128px;
    }
    @media (max-width: 345px) {
        width: 118px;
        height: 118px;
    }
}

.card-product-units {
    width: 30px;
    height: 30px;
    display: flex;
    background-color: components.$very-dark-green;
    position: absolute;
    top: -15px;
    right: -18px;
    border-radius: 30px;
    color: variables.$white;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    align-items: center;
    justify-content: center;
    @media (max-width: 370px) {
        width: 27px;
        height: 27px;
    }
    @media (max-width: 345px) {
        width: 25px;
        height: 25px;
    }
}

// Class used in Splide slider
.card--product-parent {
    background-color: transparent;
    box-shadow: none;
}

.product-selection-custom-pagination .is-active {
    background: components.$product-carousel-pagination-green !important;
}

.enrolled,
.finished {
    @extend .card-client-status;
    color: components.$primary-color;
}

.package_no_payment {
    @extend .card-client-status;
    color: var(--client-subscribed-color);
}

.potential_client,
.payment_no_package,
.duplicate {
    @extend .card-client-status;
    color: components.$dark-gray;
}

.unknown {
    @extend .card-client-status;
    margin-top: 16px;
}

.interaction-enroll-btn,
.interaction-mobile-money-payment-btn,
.interaction-card-payment-btn,
.interaction-request-reimbursement-btn {
    border-radius: 15px;
    background-color: variables.$green-interaction;
    height: auto;
    min-height: 31px;
    width: 92%;
    margin: auto;
    color: black;
    font-weight: 400;
    border-color: transparent;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
    text-transform: capitalize;
}

.interaction-request-follow-up-btn {
    @extend .interaction-enroll-btn;
    background-color: variables.$orange-interaction;
}

.interaction-cancel-package-btn,
.interaction-not-interested-btn,
.interaction-request-reimbursement-btn {
    @extend .interaction-enroll-btn;
    background-color: variables.$red-interaction;
}

.interaction-duplicate-flag-btn {
    @extend .interaction-enroll-btn;
    background-color: variables.$dark-red-interaction;
    color: variables.$white;
}

.interaction-btn-small {
    width: 92%;
}

.no-lateral-padding {
    padding-left: 0;
    padding-right: 0;
}

.downloadable-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 0;
    font-family: 'Work Sans', sans-serif;

    &--header {
        padding: 15px 15px 35px;
        border-radius: 10px 10px 0 0;
        display: flex;
    }

    &--status {
        font-size: 10px;
        font-weight: 400;
        background-color: variables.$light-grey;
        padding: 3px 5px;
        border-radius: 10px;
        display: flex;
        flex-grow: 0;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        &--icon {
            height: 15px;
        }
    }

    &--overview {
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--icon {
            padding: 5px;
        }

        &--end-icon {
            margin-left: auto;
        }

        &--title {
            font-size: 16px;
            font-weight: 600;
            white-space: wrap;
        }

        &--info {
            padding-left: 5px;
        }

        &--subtitle {
            font-size: 12px;
            font-weight: 400;
            white-space: wrap;
            color: variables.$darker-grey;
        }
    }
}
