@use '../components';

.default__btn {
    background-color: transparent;
    border: 1px solid;
    height: 45px;
    display: flex;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;
    flex-grow: 0;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: components.$black;
    width: 320px;
    font-family: 'Work Sans', sans-serif;
    width: 100%;
    padding: 0px 16px;

    &:disabled {
        background-color: #a6cfb5;
    }
}

.btn_info,
.btn_warn,
.btn_light,
.btn_danger,
.btn_solid {
    border: none;
}

.btn__primary {
    background-color: components.$primary-color;
    color: components.$white;
}

.btn_secondary {
    background-color: components.$secondary-color-green;
    color: components.$primary-color;
    border: 0px;
}

.btn_info {
    background-color: components.$follow-up-interaction-btn;
}

.btn_warn {
    background-color: components.$default-interaction-btn;
}

.btn_light {
    background-color: components.$not-interested-interaction-btn;
}

.btn_danger {
    color: components.$white;
    background-color: components.$dark-red-interaction;
}
.btn_solid {
    background-color: components.$white;
}

.btn_outline {
    border-radius: 5px;
    border: 1px solid components.$primary-color;
    background: components.$white;
}
.btn_outline span {
    color: components.$primary-color;
}

.btn-size--large {
    max-width: 471px;
}

.btn-size--small {
    min-width: 130px;
}

.btn-size--icon {
    width: max-content;
    padding: 0px;
    height: max-content;
    border: 0px;
}

.btn-size--xl {
    width: 80%;
    margin: 39px auto;
    padding-block: 50px;
    border-radius: 10px;
    font-size: 20px;
    gap: 4%;
}

.disabled-btn {
    cursor: no-drop;
}

.maroon-color-button {
    background-color: #a3743b;
    position: sticky;
    bottom: 0;
}
